import { ConfigStateService, SubscriptionService } from '@abp/ng.core';
import { DropEvent, TreeNode, TreeComponent } from '@abp/ng.components/tree';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { eFileManagementPolicyNames } from '@volo/abp.ng.file-management/config';
import {
  DirectoryContentDto,
  DirectoryDescriptorDto,
  DirectoryDescriptorInfoDto,
  FilterMission,
  DirectoryDescriptorService,
  FolderPermissionResultModel,
} from '@volo/abp.ng.file-management/proxy';
import { NzFormatEmitEvent, NzTreeNode } from 'ng-zorro-antd/tree';
import { FolderInfo } from '../../models/common-types';
import { DeleteService } from '../../services/delete.service';
import { DirectoryTreeService, ROOT_ID } from '../../services/directory-tree.service';
import { MoveService } from '../../services/move.service';
import { NavigatorService } from '../../services/navigator.service';
import { UpdateStreamService } from '../../services';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { FolderSelectedModel } from '../../models/folder-permissions/folder-selected.model';
import { RoleListInputModel } from '../../models/folder-permissions/role-list-input.model';

type RequiredDirectoryDescriptorInfoDto = Required<DirectoryDescriptorInfoDto>;

@Component({
  selector: 'abp-file-management-directory-tree',
  templateUrl: './file-management-directory-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SubscriptionService],
})
export class FileManagementDirectoryTreeComponent implements OnInit {
  private readonly ADMIN_ROLE = 'admin';
  private readonly WILDCARD_ROLE = '*';

  directories: TreeNode<RequiredDirectoryDescriptorInfoDto>[];

  createPolicy = eFileManagementPolicyNames.DirectoryDescriptorCreate;
  updatePolicy = eFileManagementPolicyNames.DirectoryDescriptorUpdate;
  deletePolicy = eFileManagementPolicyNames.DirectoryDescriptorDelete;
  contextMenuPolicy = `${this.createPolicy} || ${this.updatePolicy} || ${this.deletePolicy}`;

  permissionModalVisible = false;

  createModalVisible = false;
  createModalParentId = '';

  renameModalVisible = false;
  contentToRename: DirectoryContentDto;
  ranamedContent: DirectoryContentDto;

  moveModalVisible = false;
  folderToMove: DirectoryContentDto;
  parentOfFolderToMove: string;

  rootId = ROOT_ID;
  selectedNode: any;
  @ViewChild(TreeComponent) tree: TreeComponent;
  @Input() missionFilter: FilterMission = undefined;
  @Input() hideMainRootFolder: boolean;
  @Input() validateCapture: boolean;
  @Input() onlyViewDownload: boolean;
  @Input() isSharedLink: boolean;

  roles: Array<RoleListInputModel>;
  folderPermissionSelected = new FolderSelectedModel('', '');
  updatingPermissions: boolean;
  recentlyUpdated: Array<DirectoryDescriptorDto> = [];
  isCurrentAdmin: boolean;

  updateDirectories = (directories: TreeNode<RequiredDirectoryDescriptorInfoDto>[]) => {
    if (!directories.length) {
      this.directories = [];
      return;
    }

    if (this.validateCapture) {
      const currentCapture = this.directoryService.getCurrentCapture();
      directories = directories.filter(
        x =>
          (x.entity.captureId &&
            currentCapture &&
            x.entity.captureId.toLowerCase() == currentCapture?.toLowerCase()) ||
          x.entity.id == 'ROOT_ID',
      );
    }

    this.directories = directories.map(this.markAsParentHasChildren);

    if (this.hideMainRootFolder) {
      let rootNode = this.directories.find(r => r.id == this.rootId);

      if (rootNode) {
        rootNode.id = this.service.missionFilter.rootFolder.id;
        rootNode.title = this.service.missionFilter.rootFolder.name;
      }
    }

    this.addIconBasedOnPermissions(this.directories);

    if (this.ranamedContent) {
      const folderId = this.ranamedContent.id;
      this.ranamedContent = undefined;
      this.service.updateDirectories(folderId);
    }

    this.cdr.detectChanges();
  };

  updateSelectedNode = (directory: string) => {
    const id = directory || ROOT_ID;
    this.tree.setSelectedNode({ id });
  };

  markAsParentHasChildren = (node: TreeNode<RequiredDirectoryDescriptorInfoDto>) => {
    return {
      ...node,
      isLeaf: !node.entity.hasChildren && !node.children.length,
      children: node?.children?.map(child => this.markAsParentHasChildren(child)),
    };
  };

  constructor(
    public service: DirectoryTreeService,
    private navigator: NavigatorService,
    private move: MoveService,
    private cdr: ChangeDetectorRef,
    private deleteService: DeleteService,
    private subscription: SubscriptionService,
    private updateStream: UpdateStreamService,
    private identityUserService: IdentityUserService,
    private directoryService: DirectoryDescriptorService,
    private stateService: ConfigStateService,
  ) {}

  ngOnInit(): void {
    this.service.missionFilter = this.missionFilter;
    this.subscription.addOne(this.service.directoryTree$, this.updateDirectories);
    this.subscription.addOne(this.updateStream.currentDirectory$, this.updateSelectedNode);

    let roles = this.stateService.getDeep('currentUser.roles');
    this.isCurrentAdmin = !!roles.find(x => x == this.ADMIN_ROLE);

    if (!this.isCurrentAdmin) return;

    if (!this.isSharedLink) {
      this.identityUserService.getAssignableRoles().subscribe(res => {
        this.roles = res.items.map(x => {
          return { name: x.name, write: false, read: false, delete: false };
        });

        this.cdr.detectChanges();
      });
    }
  }

  onDrop({ dragNode, node }: DropEvent) {
    this.move
      .moveTo(
        this.nzNodeToFolderInfo(dragNode),
        this.nzNodeToFolderInfo(node),
        dragNode.parentNode.origin.id,
      )
      .subscribe();
  }

  onNodeClick(node) {
    if (node.isRoot) {
      this.onRootClick();
    } else {
      if (!node.canRead) return;
      this.navigator.goToFolder(node);
    }

    this.service.announceNewSelectedNode(node);
  }

  onRootClick() {
    this.navigator.goToRoot();
  }

  onCreate(folder: DirectoryContentDto) {
    let directory = this.findNode(this.directories, folder.id);
    if (!directory.entity.canWrite) return;

    this.createModalVisible = true;
    this.createModalParentId = folder.id;
  }

  onRename(folder: DirectoryContentDto) {
    let directory = this.findNode(this.directories, folder.id);
    if (!directory.entity.canWrite) return;

    this.renameModalVisible = true;
    this.contentToRename = { ...folder, isDirectory: true };
    this.ranamedContent = { ...folder };
  }

  onDelete(folder: DirectoryContentDto) {
    let directory = this.findNode(this.directories, folder.id);
    if (!directory.entity.canDelete) return;

    this.deleteService.deleteFolder(folder).subscribe();
  }

  onMove(folder: NzTreeNode) {
    let directory = this.findNode(this.directories, folder.origin.id);
    if (!directory.entity.canWrite) return;

    this.folderToMove = { ...folder.origin.entity, isDirectory: true };
    this.parentOfFolderToMove = folder.parentNode.origin.id;
    this.moveModalVisible = true;
  }

  onChangePermissions(folder: NzTreeNode) {
    this.directoryService.getPermissions((<any>folder).id).subscribe(res => {
      this.createRolObject(res);
      this.folderPermissionSelected = new FolderSelectedModel((<any>folder).name, (<any>folder).id);
      this.permissionModalVisible = true;

      this.cdr.detectChanges();
    });
  }

  afterFolderMoved() {
    delete this.folderToMove;
  }

  private nzNodeToFolderInfo(node: NzTreeNode): FolderInfo {
    return {
      id: node.origin.id,
      name: node.origin.title,
    };
  }

  refresh() {
    this.service.updateDirectories();
    this.cdr.detectChanges();
  }

  onExpandedKeysChange(event: NzFormatEmitEvent) {
    if (!event.node.origin.entity.canRead) {
      return;
    }

    if (event.node.isExpanded && event.node.key !== ROOT_ID && !event.node.children.length) {
      this.service.missionFilter = this.missionFilter;
      this.service.updateDirectories(event.node.key);
    }
  }

  handleOnClosePermissionsModal() {
    this.permissionModalVisible = false;
  }

  handleOnSavePermissionsModal(event: FolderPermissionResultModel) {
    this.updatingPermissions = true;
    this.directoryService
      .updatePermissions(this.folderPermissionSelected.id, event)
      .subscribe(res => {
        this.permissionModalVisible = false;
        this.updatePermissionInTree(this.directories, res);
        this.recentlyUpdated.push(res);
        this.updatingPermissions = false;

        this.cdr.detectChanges();
      });
  }

  canRenameFolder(item: DirectoryContentDto): boolean {
    if (!item) return false;

    return item.canWrite && item.canDelete && !this.onlyViewDownload;
  }

  canCreateFolder(item: DirectoryContentDto): boolean {
    if (!item) return true;

    return item.canWrite && !this.onlyViewDownload;
  }

  canDeleteFolder(item: DirectoryContentDto): boolean {
    if (!item) return false;

    return item.canDelete && !this.onlyViewDownload;
  }

  displayFolderActions(node: DirectoryContentDto): boolean {
    if (!node || node.id == this.rootId) return true;

    return this.canDeleteFolder(node) || this.canRenameFolder(node) || this.canCreateFolder(node);
  }

  private createRolObject(folderInfo: DirectoryDescriptorDto) {
    this.roles.forEach(x => {
      Object.entries(x).forEach(([key, value]) => {
        if (key == 'name') return;

        x[key] = !!(
          folderInfo[key] &&
          (folderInfo[key].find(t => t == x.name) ||
            folderInfo[key].find(t => t == this.WILDCARD_ROLE))
        );
      });
    });
  }

  private addIconBasedOnPermissions(directories: TreeNode<RequiredDirectoryDescriptorInfoDto>[]) {
    directories.forEach(x => {
      let recentlyUpdated = this.recentlyUpdated.find(u => u.id == x.id);

      if (recentlyUpdated) {
        x.entity.canDelete = recentlyUpdated.canDelete;
        x.entity.canRead = recentlyUpdated.canRead;
        x.entity.canWrite = recentlyUpdated.canWrite;
      }

      if (x.children.length > 0) this.addIconBasedOnPermissions(x.children);

      if (x.entity.canDelete) {
        x.icon = 'fas fa-lock-open tree-icon';
        return;
      }

      if (x.entity.canWrite) {
        x.icon = 'fas fa-user-lock tree-icon';
        return;
      }

      if (x.entity.canRead) {
        x.icon = 'far fa-eye tree-icon';
        return;
      }

      x.icon = 'fas fa-lock tree-icon';
    });
  }

  private updatePermissionInTree(
    directories: TreeNode<RequiredDirectoryDescriptorInfoDto>[],
    folder: DirectoryDescriptorDto,
  ): void {
    let updated = directories.find(x => x.id == folder.id);

    if (updated) {
      updated.entity.canDelete = folder.canDelete;
      updated.entity.canRead = folder.canRead;
      updated.entity.canWrite = folder.canWrite;

      if (folder.canDelete) {
        updated.icon = 'fas fa-lock-open tree-icon';
        return;
      }

      if (folder.canWrite) {
        updated.icon = 'fas fa-user-lock tree-icon';
        return;
      }

      if (folder.canRead) {
        updated.icon = 'far fa-eye tree-icon';
        return;
      }

      updated.icon = 'fas fa-lock tree-icon';
      return;
    }

    for (let directory of directories) {
      if (directory.children) this.updatePermissionInTree(directory.children, folder);
    }
  }

  private findNode(
    directories: TreeNode<RequiredDirectoryDescriptorInfoDto>[],
    id: string,
  ): TreeNode<RequiredDirectoryDescriptorInfoDto> {
    let node = directories.find(x => x.id == id);

    if (node) return node;

    for (let child of directories) {
      if (!child.children) continue;

      let item = this.findNode(child.children, id);

      if (item) return item;
    }
  }
}
