import { ABP, ConfigStateService, ListService, PagedResultDto } from '@abp/ng.core';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import {
  NotificationAudience,
  NotificationsManagerDto,
} from 'projects/notifications-service/src/lib/proxy/notifications-service/basics';
import { PaginationSortingAndGlobalSearch } from '../../../shared/grid-filters/models/pagination-sorting-and-global-search';
import { NotificationFiltersManager } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-filters';
import { FilterConfig } from '../../../shared/grid-filters/models/filter-config.model';
import { NotificationFilterManagerColumns } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-filters-columns';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { combineLatest, Observable, switchMap } from 'rxjs';
import {
  enumState,
  GridViewsCreateDto,
  GridViewsDto,
  StatusMissionOrderDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { enumNotificationCategoryType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-category-type';
import { enumNotificationTriggerType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-trigger-type';
import { enumNotificationType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-notification-type';
import { FilterCondition } from '../../../shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../shared/grid-filters/models/filter-type.enum';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationSettingsForLocalManagerStorage } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-settings-for-local-storage';
import {
  assignedFilterDisplayNames,
  assignedFilterEnum,
} from 'projects/missions-service/src/lib/proxy/missions-service/shared/assignedFilter.enum';
import { GridViewsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { NotificationManagerViewFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-view-filters';
import { CustomViewDataModel } from 'projects/missions-service/src/lib/basics/missions/components/models/custom-view-data.model';
import { CustomViewNotificationModalComponent } from 'projects/missions-service/src/lib/basics/missions/components/custom-view-notification-modal-component/custom-view-notification-modal-component.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnStatus } from '../../columns/components/column-displayer/column-displayer.component';
import { CustomNotificationViewDataModel } from 'projects/missions-service/src/lib/basics/missions/components/models/custom-notification-view-data.model';
import { StatusMissionOrderService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/status-mission-order.service';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics/customers.service';
import {
  CustomersDto,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics/models';
import { TemplateContentService } from 'projects/text-template-management/proxy/src/lib';
import { NotificatioManagerStatusFilterTypeEnum } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/otificatio-manager-status-filter-type-enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CustomerAutocompleteComponent } from '../../business-configuration/autocomplete/autocomplete.component';
import {
  ConfigurationAttributeTypesDto,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';

@Component({
  selector: 'app-notification-manager',
  templateUrl: './notification-manager.component.html',
  styleUrls: ['./notification-manager.component.scss'],
})
export class NotificationManagerComponent {
  @ViewChild('modal') modal: TemplateRef<any>;
  @ViewChild('toggleModal') toggleModal: TemplateRef<any>;
  @ViewChild(CustomerAutocompleteComponent) customerAutocomplete: CustomerAutocompleteComponent;

  data: PagedResultDto<NotificationsManagerDto> = {
    items: [],
    totalCount: 0,
  };

  IsEnableAction = false;
  isBusy = false;
  statusesMap: { [id: string]: string } = {};

  currentUserId: string;

  clientMaxResultCount = 10;
  valuesMaxResultCount = [];
  pageNumber = 0;
  action: any;
  MaxResultCount = 100;
  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';
  selectedAssignedFilter: number = 0;
  viewConfigSelected: NotificationManagerViewFilters;
  selected: NotificationsManagerDto;
  contentToShow: SafeHtml;

  @Input() missionId?: string;

  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearch = {
    skipCount: 0,
    maxResultCount: 10,
  };

  notificationGridFilters: NotificationFiltersManager = {};
  filterConfig: FilterConfig = NotificationFilterManagerColumns;

  notificationCategoryTypeDictionary: { [key: number]: string } = {
    [enumNotificationCategoryType.QAQCAlerts]: 'QA/QC Alerts',
    [enumNotificationCategoryType.CustomerUpdates]: 'Customer Updates',
  };

  notificationTriggerTypeDictionary: { [key: number]: string } = {
    [enumNotificationTriggerType.StatusChange]: 'Status Change',
  };

  notificationTypeDictionary: { [key: number]: string } = {
    [enumNotificationType.EmailMessage]: 'Email Message',
    [enumNotificationType.HubNotification]: 'Hub Notification',
    [enumNotificationType.SmsNotification]: 'SMS Notification',
    [enumNotificationType.WebNotification]: 'Web Notification',
  };

  form: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
    customerId: new FormControl('-'),
    statusId: new FormControl(12), // Request refly as default (this status has multiple templates)
  });

  viewSelected: GridViewsDto;

  lstAssignedFilter = Object.keys(assignedFilterEnum)
    .filter(key => key == 'Everything')
    .map(key => ({
      value: assignedFilterEnum[key],
      description: assignedFilterDisplayNames[assignedFilterEnum[key]],
    }));

  existingViews: Array<GridViewsDto>;
  isAdminUser: boolean = false;
  filterCustomers = {} as GetCustomerInput;

  customers: CustomersDto[];
  statuses: StatusMissionOrderDto[];
  changingPageMaxResoultCount: boolean = false;

  customerId: string;

  defaultCustomer: CustomersDto;

  constructor(
    public readonly list: ListService,
    private spinner: NgxSpinnerService,
    private service: NotificationsService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    private stateService: ConfigStateService,
    public readonly gridViewsService: GridViewsService,
    private dialogService: MatDialog,
    private snackBar: MatSnackBar,
    private readonly statusService: StatusMissionOrderService,
    private readonly customerService: CustomersService,
    private readonly templateService: TemplateContentService,
    private domSanitizer: DomSanitizer,
  ) { }
  ngOnDestroy(): void { }

  ngOnInit(): void {
    this.defaultCustomer = <CustomersDto>{ name: 'All Customers', id: null };

    this.currentUserId = this.stateService.getDeep('currentUser.id');
    const currentRoles = this.stateService.getDeep('currentUser.roles') as string[];

    this.isAdminUser = currentRoles.includes('admin');
    this.filterCustomers.maxResultCount = 1000;

    this.spinner.show();

    combineLatest([
      this.getViewList(this.currentUserId, this.isAdminUser),
      this.customerService.getList(this.filterCustomers),
      this.statusService.getList({ maxResultCount: 100 }),
      this.service.getAudienceList(),
      this.getMaxCountAttribute(),
    ]).subscribe({
      next: ([viewList, customers, statuses, audience, maxCountAttribute]) => {
        this.setMaxCountAttribute(maxCountAttribute);
        this.customers = customers.items;
        this.statuses = statuses.items;

        this.statusesMap = statuses.items?.reduce(
          (result, dto) => {
            result[dto.statusEnum] = dto.statusDescription;
            return result;
          },
          {} as { [id: string]: string },
        );

        this.callApiWithFilters();

        this.existingViews = viewList;

        this.updateAudienceOption(audience);

        this.spinner.hide();
      },
      error: err => {
        console.error(`Mission List, error while fetching data: ${err}`);
        this.spinner.hide();
      },
    });
  }

  private getMaxCountAttribute(): Observable<PagedResultDto<ConfigurationAttributeTypesDto>> {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    return this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      );
  }

  private setMaxCountAttribute(res: PagedResultDto<ConfigurationAttributeTypesDto>) {
    let attribute = res.items.find(_ => true);
    this.MaxResultCount = parseInt(attribute.description);
  }


  private statusId(): number {
    return this.form.get('statusId')?.value;
  }


  handleCustomerSelection(event: any) {
    this.customerId = event.customerId;
    this.callApiWithFilters();
  }

  handleOnCustomerClear() {
    this.customerId = null;
    this.callApiWithFilters();
  }

  removeFilter(column: string) {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => {
        if (condition.column === column) {
          return { ...condition, existingValues: [], extraValues: undefined };
        }
        return condition;
      }),
    };

    // Clear the specific filter
    this.notificationGridFilters[column as keyof NotificationManagerViewFilters] = [];

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  notificationDisabled(item: NotificationsManagerDto): boolean {
    return (
      item.disabled ||
      item.notificationDefinitionStatuses?.some(q =>
        q.notificationDefinitionStatusCustomer?.some(
          r => r.customerId == this.customerId && r.disabled,
        ),
      )
    );
  }

  handleOnViewSelected(id: string) {
    let completeView = this.existingViews.find(x => x.id == id);

    if (!completeView) return;

    this.viewSelected = completeView;

    let filtersObj: NotificationManagerViewFilters = JSON.parse(completeView.viewDefinition);

    this.notificationGridFilters = { ...filtersObj };

    this.paginationSortingAndGlobalSearch.maxResultCount = filtersObj.maxResultCount;
    this.paginationSortingAndGlobalSearch.filter = filtersObj.filter;

    this.viewConfigSelected = filtersObj;

    this.applyViewFilters(filtersObj);

    this.handleFilterFromView();
  }

  handleOnHover(view: GridViewsDto, hover: boolean, event: any) {
    view.displayStar = hover;
  }

  handleOnCreateCustomView(): void {
    const currentFilters: NotificationManagerViewFilters = {
      ...this.notificationGridFilters,
      sorting: this.paginationSortingAndGlobalSearch.sorting,
      maxResultCount: this.paginationSortingAndGlobalSearch.maxResultCount,
    };

    const modalData: CustomNotificationViewDataModel = {
      data: currentFilters,
      isEdition: false,
      isManager: true,
    };

    const dialogRef = this.dialogService.open(CustomViewNotificationModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: modalData,
    });

    dialogRef.afterClosed().subscribe((result: NotificationManagerViewFilters) => {
      if (!result) return;

      let viewDefinition = { ...result, ['viewName']: undefined, ['privateView']: undefined };

      let creationViewModel: GridViewsCreateDto = {
        name: result.viewName,
        private: !this.isAdminUser || !!result.privateView,
        viewDefinition: JSON.stringify(viewDefinition),
        userId: this.currentUserId,
        viewType: 2,
      };

      this.gridViewsService.create(creationViewModel).subscribe({
        next: (res: GridViewsDto) => {
          this.existingViews.push(res);

          this.snackBar.open('New view added.', 'Close', {
            duration: 3000,
          });
        },
        error: err => console.error('error saving view', err),
      });
    });
  }

  showedColumns = {
    types: true,
    triggerTypes: true,
    categoryTypes: true,
    tags: true,
    creationDates: true,
    notificationName: true,
    name: true,
    summary: true,
    status: true,
    audience: true,
    statuses: true,
  };

  allowedByView(column: string): boolean {
    if (!this.viewSelected) return true;

    return this.viewConfigSelected?.columns?.includes(column);
  }

  columnsStatus() {
    const columnsStatus: ColumnStatus[] = [
      {
        columnName: 'types',
        softColumnName: 'Types',
        defaultChecked: this.showedColumns.types,
      },
      {
        columnName: 'triggerTypes',
        softColumnName: 'Trigger Types',
        defaultChecked: this.showedColumns.triggerTypes,
      },
      {
        columnName: 'categoryTypes',
        softColumnName: 'Category Types',
        defaultChecked: this.showedColumns.categoryTypes,
      },
      {
        columnName: 'name',
        softColumnName: 'Name',
        defaultChecked: this.showedColumns.name,
      },
      {
        columnName: 'tags',
        softColumnName: 'Tags',
        defaultChecked: this.showedColumns.tags,
      },
      {
        columnName: 'creationDates',
        softColumnName: 'Creation Dates',
        defaultChecked: this.showedColumns.creationDates,
      },
      {
        columnName: 'summary',
        softColumnName: 'Summary',
        defaultChecked: this.showedColumns.summary,
      },
      {
        columnName: 'statuses',
        softColumnName: 'Statuses',
        defaultChecked: this.showedColumns.statuses,
      },
    ];

    return columnsStatus;
  }

  handleFilterFromView() {
    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  private applyViewFilters(viewFilters: NotificationManagerViewFilters) {
    for (let condition of this.filterConfig.conditions) {
      condition.existingValues = viewFilters[condition.column] || [];
    }

    this.form.get('searchInput')?.setValue(viewFilters.filter);
  }

  private getViewList(userId: string, isAdmin: boolean): Observable<Array<GridViewsDto>> {
    return this.gridViewsService.getAll(userId, isAdmin, 2);
  }

  getDisplayValue(item: any) {
    if (this.viewSelected) {
      return this.viewSelected.name;
    }

    let selected = this.lstAssignedFilter.find(z => z.value == item);

    return selected?.description || '-';
  }

  onGlobalSearch() {
    this.paginationSortingAndGlobalSearch.filter = this.form.get('searchInput')?.value.trim();

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  private storeFilterSettings() {
    const filterSettings: NotificationSettingsForLocalManagerStorage = {
      notificationFilters: this.notificationGridFilters,
      paginationSortingAndGlobalSearch: this.paginationSortingAndGlobalSearch,
    };
    localStorage.setItem(
      `filterSettings_notifications_manager_${this.currentUserId}`,
      JSON.stringify(filterSettings),
    );
  }

  callApiWithFilters() {
    this.spinner.show();
    let filterType: NotificatioManagerStatusFilterTypeEnum =
      NotificatioManagerStatusFilterTypeEnum.WithStatus;

    if (this.statusId() == -1) {
      filterType = NotificatioManagerStatusFilterTypeEnum.CodeImplemented;
    } else if (this.statusId() == -2) filterType = NotificatioManagerStatusFilterTypeEnum.NoStatus;

    this.service
      .getManagerList(
        this.notificationGridFilters,
        this.paginationSortingAndGlobalSearch,
        this.customerId === '-' ? null : this.customerId,
        filterType != NotificatioManagerStatusFilterTypeEnum.WithStatus ? null : this.statusId(),
        filterType,
      )
      .subscribe({
        next: (data: PagedResultDto<NotificationsManagerDto>) => {
          this.data = data;
          this.spinner.hide();
          this.changingPageMaxResoultCount = false;
        },
        error: error => {
          console.log(error);
          this.spinner.hide();
        },
      });
  }

  onFiltersApplied(updatedFilters: FilterConfig) {
    updatedFilters.conditions.forEach(updatedCondition => {
      const originalCondition = this.filterConfig.conditions.find(
        c => c.column === updatedCondition.column,
      );
      if (originalCondition) {
        originalCondition.existingValues = updatedCondition.existingValues;

        this.notificationGridFilters[updatedCondition.column] = updatedCondition.existingValues;
      }
    });

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.callApiWithFilters();
  }

  onSort(sortInfo: any) {
    if (sortInfo.sorts && sortInfo.sorts.length > 0) {
      const sort = sortInfo.sorts[0];
      const sortingCriteria = `${sort.prop} ${sort.dir}`;

      this.paginationSortingAndGlobalSearch.sorting = sortingCriteria;

      this.callApiWithFilters();
    }
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    // Return the description of the option if it's a dropdown
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    // Return the value itself for other types
    return value;
  }

  setPage(pageInfo: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: pageInfo.offset * pageInfo.pageSize,
      maxResultCount: pageInfo.pageSize,
    };
    if (!this.changingPageMaxResoultCount) {
      this.storeFilterSettings();
      this.callApiWithFilters();
    }
  }

  onBeforePageSizeChange() {
    this.changingPageMaxResoultCount = true;
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    // Reset filters
    for (let condition of this.filterConfig.conditions) {
      this.notificationGridFilters[condition.column as keyof NotificationFiltersManager] = [];
    }

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.callApiWithFilters();
  }

  view(notification: NotificationsManagerDto) {
    this.spinner.show();
    this.templateService.getReplaced({ templateName: notification.templateName }).subscribe(r => {
      this.spinner.hide();
      this.contentToShow = this.domSanitizer.bypassSecurityTrustHtml(r);
      this.selected = notification;

      this.dialogService.open(this.modal, {
        autoFocus: false,
      });
    });
  }

  showDisableEnable(notification: NotificationsManagerDto, IsEnableAction: boolean) {
    this.selected = notification;

    this.IsEnableAction = IsEnableAction;

    const dialogRef: MatDialogRef<any> = this.dialogService.open(this.toggleModal, {
      autoFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe({
      next: (state: boolean) => {
        // True assumes the ok button was pressed

        let filterType: NotificatioManagerStatusFilterTypeEnum =
          NotificatioManagerStatusFilterTypeEnum.WithStatus;

        if (this.statusId() == -1) {
          filterType = NotificatioManagerStatusFilterTypeEnum.CodeImplemented;
        } else if (this.statusId() == -2)
          filterType = NotificatioManagerStatusFilterTypeEnum.NoStatus;

        if (state) {
          this.isBusy = true;
          this.service
            .enableDisableNotification(
              this.selected.id,
              this.customerId,
              filterType != NotificatioManagerStatusFilterTypeEnum.WithStatus
                ? null
                : this.statusId(),
              this.IsEnableAction,
              filterType,
            )
            .subscribe(_ => {
              this.isBusy = false;
              this.callApiWithFilters();
            });
        }
      },
    });
  }

  getDestination(notification: NotificationsManagerDto): string[] {
    let destination: string = notification.audience?.description;

    if (!destination) destination = notification.destination;

    return destination?.split(',') ?? [];
  }

  private updateAudienceOption(data: NotificationAudience[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'audience');
    if (condition) {
      condition.options = data.map(item => ({ id: item.code, description: item.description }));
    }
  }
}
