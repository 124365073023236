<abp-file-management-modal title="CreateFolder" [parent]="self">
  <form [formGroup]="form" (keyup.enter)="save()">
    <div class="form-group">
      <label for="createFolderInput">{{ 'FileManagement::Name' | abpLocalization }} *</label>
      <input formControlName="name" type="text" class="form-control" id="createFolderInput" />
      <mat-error *ngIf="form.get('name').hasError('isFolderNameNotValid')">
        Please enter a valid folder name.
      </mat-error>
    </div>
  </form>
</abp-file-management-modal>
