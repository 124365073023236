import { mapEnumToOptions } from '@abp/ng.core';

export enum assignedFilterEnum {
  MyMission = 0,
  MyTeam = 1,
  All = 2,
  Interested = 3,
  MyResponsibilities = 4,
}

export const assignedFilterDisplayNames: Record<assignedFilterEnum, string> = {
  [assignedFilterEnum.MyMission]: 'My Current Assignments',
  [assignedFilterEnum.MyTeam]: 'Previously Worked',
  [assignedFilterEnum.All]: 'Everything',
  [assignedFilterEnum.Interested]: 'My Interested',
  [assignedFilterEnum.MyResponsibilities]: 'My Responsibilities',
};

export const EnumRecurreceOptions = mapEnumToOptions(assignedFilterEnum);
