<div>
  <h3>{{ 'missionsService::Metrics' | abpLocalization }}</h3>

  <div class="row">
    <div class="col-lg-4 col-md-4" *ngIf="showDays">
      <app-info-card-with-action
        [icon]="'calendar_today'"
        [title]="'missionsService::TimeonCurrentStatus' | abpLocalization"
        class="fixed-height"
        [actionIconVisible]="false"
      >
        <div>
          <app-subtitle-description
            [subtitle]="statusName"
            [description]="gettimeOnCurrentStatusDescription()"
          >
          </app-subtitle-description>
        </div>
      </app-info-card-with-action>
    </div>
    <div class="col-lg-4 col-md-4">
      <app-info-card-with-action
        [icon]="'radar'"
        [title]="'missionsService::Reflys' | abpLocalization"
        class="fixed-height"
        [actionIconVisible]="false"
      >
        <div>
          <app-subtitle-description
            [subtitle]="'missionsService::Total' | abpLocalization"
            [description]="getReflyDescription()"
          >
          </app-subtitle-description>
        </div>
      </app-info-card-with-action>
    </div>
  </div>
</div>

<h3>{{ 'missionsService::Milestones' | abpLocalization }}</h3>
<lib-show-sla-milestones
  *ngIf="showTable"
  [missionId]="missionId"
  [fromMetrics]="true"
></lib-show-sla-milestones>
