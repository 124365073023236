import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetMissionStatusRolesInput,
  MissionStatusRoleCreateDto,
  MissionStatusRoleDto,
  MissionStatusRoleExcelDownloadDto,
  MissionStatusRoleUpdateDto,
  MissionStatusRoleLookup,
} from '../../lookups/models';
import type { DownloadTokenResultDto } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class MissionStatusRoleService {
  apiName = 'CoreService';

  create = (input: MissionStatusRoleCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionStatusRoleDto>(
      {
        method: 'POST',
        url: '/api/core-service/mission-status-roles',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/core-service/mission-status-roles/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionStatusRoleDto>(
      {
        method: 'GET',
        url: `/api/core-service/mission-status-roles/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/core-service/mission-status-roles/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetMissionStatusRolesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionStatusRoleDto>>(
      {
        method: 'GET',
        url: '/api/core-service/mission-status-roles',
        params: {
          filterText: input.filterText,
          statusId: input.statusId,
          statusName: input.statusName,
          roleId: input.roleId,
          roleName: input.roleName,
          responsibility: input.responsibility,
          interested: input.interested,
          sorting: input.sorting,
          isPaginated: input.isPaginated,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: MissionStatusRoleExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/core-service/mission-status-roles/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: MissionStatusRoleUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionStatusRoleDto>(
      {
        method: 'PUT',
        url: `/api/core-service/mission-status-roles/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  filterByRoles = (input: Array<string>, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionStatusRoleLookup[]>(
      {
        method: 'POST',
        url: '/api/core-service/mission-status-roles/filter/role',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
