import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetMissionNotesInput,
  MissionNoteCreateDto,
  MissionNoteDto,
  MissionNoteExcelDownloadDto,
  MissionNoteUpdateDto,
  MissionNoteWithNavigationPropertiesDto,
} from '../../relationals/models';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../../shared/models';
import { UserNoteDto } from 'projects/flyguys/src/app/pilot-sourcing/models/user-note-dto';

@Injectable({
  providedIn: 'root',
})
export class MissionNoteService {
  apiName = 'missionsService';

  create = (input: MissionNoteCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionNoteDto>(
      {
        method: 'POST',
        url: '/api/missions-service/mission-notes',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/missions-service/mission-notes/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionNoteDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-notes/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-notes/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetMissionNotesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<MissionNoteWithNavigationPropertiesDto>>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-notes',
        params: {
          filterText: input.filterText,
          descriptionMission: input.descriptionMission,
          descriptionNote: input.descriptionNote,
          state: input.state,
          missionsId: input.missionsId,
          notesId: input.notesId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: MissionNoteExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/missions-service/mission-notes/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getMissionsLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-notes/missions-lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getNotesLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: '/api/missions-service/mission-notes/notes-lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionNoteWithNavigationPropertiesDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-notes/with-navigation-properties/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: string, input: MissionNoteUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionNoteDto>(
      {
        method: 'PUT',
        url: `/api/missions-service/mission-notes/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getUsersByMissionId = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<UserNoteDto>>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-notes/mission/${missionId}/users`,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
