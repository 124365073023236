<div class="container-fluid">
  <div class="d-flex justify-content-between align-items-center mb-3">
    <div>
      <h3>Shipments</h3>
    </div>

    <div class="d-flex align-items-center gap-2">
      <button mat-button type="button" class="common-button" (click)="openAddShipmentModal()">
        <mat-icon>add</mat-icon>
        Add Shipment
      </button>
    </div>
  </div>

  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="false"
    [zIndex]="111"
  >
    <p style="color: #bc5a2e">Loading Shipments...</p>
  </ngx-spinner>

  <!-- <form [formGroup]="form" (submit)="onGlobalSearch()">
    <div class="d-flex mb-3 pt-2">
      <mat-form-field class="d-flex w-80 pe-2" subscriptSizing="dynamic">
        <mat-label>Search</mat-label>
        <input
          matInput
          placeholder="Search"
          formControlName="searchInput"
          (keyup.enter)="onGlobalSearch()"
        />
        <mat-icon matSuffix class="cursor-pointer blue-color" (click)="onGlobalSearch()"
          >search</mat-icon
        >
      </mat-form-field>
    </div>
  </form> -->

  <div class="grid" *ngIf="data && data.items">
    <ngx-datatable
      #dataTable
      [rows]="data.items"
      [headerHeight]="60"
      [footerHeight]="55"
      [rowHeight]="50"
      [offset]="pageNumber"
      [count]="data.totalCount"
      [externalPaging]="true"
      [reorderable]="false"
      [swapColumns]="false"
      [limit]="list.maxResultCount"
      (page)="setPage($event)"
      (sort)="onSort($event)"
      default
      class="flg-grid-table"
    >
      <ngx-datatable-column
        name="Tracking Number"
        prop="trackingNumber"
        [width]="200"
        [resizeable]="true"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.trackingNumber }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Carrier" prop="carrierName" [width]="200" [resizeable]="true">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.carrierName }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Est. Arrival"
        prop="estimatedArrivalTime"
        [width]="200"
        [resizeable]="true"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.estimatedArrivalTime | date: 'MM/dd/yyyy' }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Created By" prop="creatorName" [width]="200" [resizeable]="true">
        <ng-template let-row="row" ngx-datatable-cell-template>
          {{ row.creatorName }}
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Notes" prop="notes" [width]="100" [resizeable]="true">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div [matTooltip]="getTooltip(row?.notes)">
            {{ row.notes }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="Actions"
        [sortable]="false"
        [frozenRight]="true"
        [width]="120"
        [resizeable]="false"
        [headerClass]="'actions-column text-center'"
        [cellClass]="'text-center'"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div class="flg-grid-table-actions">
            <div class="secondary-actions">
              <button class="btn single-action" (click)="view(row)" matTooltip="Shipment Details">
                <span class="material-symbols-outlined"> info </span>
              </button>
              <button class="btn single-action" (click)="edit(row)" matTooltip="Edit Shipment">
                <span class="material-symbols-outlined"> edit </span>
              </button>
              <button class="btn single-action" (click)="delete(row)" matTooltip="Delete Shipment">
                <span class="material-symbols-outlined"> delete </span>
              </button>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-curPage="curPage"
        >
          <app-table-paginator
            [(maxResultCount)]="list.maxResultCount"
            [(clientMaxResultCount)]="clientMaxResultCount"
            [rowCount]="rowCount"
            [pageSize]="pageSize"
            [(pageNumber)]="pageNumber"
            [curPage]="curPage"
            [dataTable]="dataTable"
            (clientMaxResultCountChange)="onPaginationChange($event)"
          >
          </app-table-paginator>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</div>
