import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PagedResultDto } from '@abp/ng.core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { ShipmentCarrierDto } from './models/shipment-carrier.dto';
import { AddShipmentDto } from './models/add-shipment.dto';
import { ShipmentCreationDto } from './models/shipment-creation.dto';
import { ShipmentCarrierService } from './services/shipment-carrier.service';
import { ShipmentService } from './services/shipment.service';
import { ToasterService } from '@abp/ng.theme.shared';
import { ShipmentDto } from './models/shipment.dto';

@Component({
  selector: 'app-add-shipment-modal',
  templateUrl: './add-shipment-modal.component.html',
  styleUrls: ['./add-shipment-modal.component.scss'],
})
export class AddShipmentModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  carriers: ShipmentCarrierDto[] = [];
  loading = false;
  private trackingNumberSearch$ = new Subject<string>();
  private subscriptions = new Subscription();
  private existingShipmentId: string | null = null;
  private readonly emptyGuid = '00000000-0000-0000-0000-000000000000';
  private invalidTrackingNumbers: Set<string> = new Set();
  title: string;

  constructor(
    public dialogRef: MatDialogRef<AddShipmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddShipmentDto,
    private formBuilder: FormBuilder,
    private shipmentCarrierService: ShipmentCarrierService,
    private shipmentService: ShipmentService,
    private toaster: ToasterService,
  ) {
    this.form = this.formBuilder.group({
      trackingNumber: ['', Validators.required],
      carrierId: ['', Validators.required],
      estimatedArrivalTime: ['', Validators.required],
      notes: [''],
    });

    // Only set up tracking number search for new shipments (not in edit mode)
    if (!this.data.isEdit) {
      this.subscriptions.add(
        this.trackingNumberSearch$
          .pipe(
            debounceTime(300),
            distinctUntilChanged(),
            filter(term => term.length > 0),
          )
          .subscribe(trackingNumber => this.searchShipment(trackingNumber)),
      );

      this.subscriptions.add(
        this.form.get('trackingNumber').valueChanges.subscribe(value => {
          if (value) {
            // Clear any previous duplicate errors when the tracking number changes
            const normalizedValue = value.trim();
            if (this.invalidTrackingNumbers.has(normalizedValue)) {
              this.invalidTrackingNumbers.delete(normalizedValue);
              this.form.get('trackingNumber').setErrors(null);
            }
            this.trackingNumberSearch$.next(value);
          } else {
            this.enableFormControls();
            this.existingShipmentId = null;
          }
        }),
      );
    }

    this.setTitle();
  }

  ngOnInit() {
    this.loading = true;
    this.shipmentCarrierService.getList({ maxResultCount: 1000 }).subscribe({
      next: (result: PagedResultDto<ShipmentCarrierDto>) => {
        this.carriers = result.items.sort((a, b) => a.name.localeCompare(b.name));
        this.loading = false;
      },
      error: () => {
        this.toaster.error('Error loading carriers. Please try again.');
        this.loading = false;
      },
    });
    if (this.data.shipmentDetails) {
      this.form.patchValue({
        trackingNumber: this.data.shipmentDetails.trackingNumber,
        carrierId: this.data.shipmentDetails.carrierId,
        estimatedArrivalTime: this.data.shipmentDetails.estimatedArrivalTime
          ? new Date(this.data.shipmentDetails.estimatedArrivalTime)
          : null,
        notes: this.data.shipmentDetails.notes,
      });

      if (this.data.isReadOnly) {
        this.form.disable();
      } else if (this.data.isEdit) {
        this.form.get('trackingNumber').disable();
      }

      this.existingShipmentId = this.data.shipmentDetails.id;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setTitle(): void {
    if (this.data.isReadOnly) {
      this.title = 'Shipment Details';
    } else if (this.data.isEdit) {
      this.title = 'Edit Shipment';
    } else {
      this.title = 'Add Shipment';
    }
  }

  private isValidShipment(shipment: any): boolean {
    return (
      shipment &&
      shipment.id !== this.emptyGuid &&
      shipment.trackingNumber &&
      shipment.carrierId !== this.emptyGuid
    );
  }

  private searchShipment(trackingNumber: string) {
    this.loading = true;
    const normalizedTrackingNumber = trackingNumber.trim();

    // If the tracking number is already one that belongs to this mission, skip the API call
    if (this.invalidTrackingNumbers.has(normalizedTrackingNumber)) {
      this.form.get('trackingNumber').setErrors({ duplicateInMission: true });
      this.enableFormControls();
      this.existingShipmentId = null;
      this.loading = false;
      return;
    }

    this.shipmentService.searchByTrackingNumber(normalizedTrackingNumber).subscribe({
      next: (shipmentFromDb: ShipmentDto) => {
        if (this.isValidShipment(shipmentFromDb)) {
          this.existingShipmentId = shipmentFromDb.id;

          this.shipmentService.getShipmentsByMissionId(this.data.missionId).subscribe({
            next: (shipmentsInMission: ShipmentDto[]) => {
              if (shipmentsInMission?.some(shipment => shipment.id === this.existingShipmentId)) {
                this.toaster.warn('Shipment already exists for this mission.');
                this.invalidTrackingNumbers.add(normalizedTrackingNumber);
                this.form.get('trackingNumber').setErrors({ duplicateInMission: true });
                this.enableFormControls();
                this.existingShipmentId = null;
                this.loading = false;
                return;
              }

              // Shipment exists but not in this mission - autocomplete the form
              this.form.patchValue({
                carrierId: shipmentFromDb.carrierId,
                estimatedArrivalTime:
                  shipmentFromDb.estimatedArrivalTime &&
                  shipmentFromDb.estimatedArrivalTime !== '0001-01-01T00:00:00'
                    ? new Date(shipmentFromDb.estimatedArrivalTime)
                    : null,
                notes: shipmentFromDb.notes || '',
              });

              this.disableFormControls();
              this.loading = false;
            },
          });
        } else {
          this.enableFormControls();
          this.existingShipmentId = null;
          this.loading = false;
        }
      },
      error: () => {
        this.toaster.error('Error searching shipment. Please try again.');
        this.loading = false;
        this.enableFormControls();
        this.existingShipmentId = null;
      },
    });
  }

  private disableFormControls() {
    this.form.get('carrierId').disable();
    this.form.get('estimatedArrivalTime').disable();
    this.form.get('notes').disable();
  }

  private enableFormControls() {
    this.form.get('carrierId').enable();
    this.form.get('estimatedArrivalTime').enable();
    this.form.get('notes').enable();
  }

  onClickClose(): void {
    this.dialogRef.close();
  }

  onClickSave(): void {
    if (this.form.valid) {
      const estimatedArrivalDate = this.form.get('estimatedArrivalTime').value;

      const shipment: ShipmentCreationDto = {
        missionId: this.data.missionId,
        trackingNumber: this.form.get('trackingNumber').value,
        carrierId: this.form.get('carrierId').value,
        estimatedArrivalDate: estimatedArrivalDate ? estimatedArrivalDate.toISOString() : null,
        note: this.form.get('notes').value,
        creatorName: this.data.creatorName,
      };

      if (this.data.isEdit) {
        // For edit mode, add the shipment ID and isEdit flag
        shipment.shipmentId = this.existingShipmentId;
        this.dialogRef.close({ ...shipment, isEdit: true });
      } else {
        // For create mode, add shipmentId only if it's an existing shipment from search
        if (this.existingShipmentId) {
          shipment.shipmentId = this.existingShipmentId;
        }
        this.dialogRef.close(shipment);
      }
    }
  }
}
