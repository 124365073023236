import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CustomerWithNavigationPropertiesDto,
  CustomersCreateDto,
  CustomersDto,
  CustomersExcelDownloadDto,
  CustomersUpdateDto,
  GetCustomerInput,
  SharedLinkDto,
} from '../../basics/models';
import type { DownloadTokenResultDto, LookupRequestDto, LookupUserDto } from '../../shared/models';
import { CreateCustomer } from '../../../../../../../flyguys/src/app/components/orders/model/order-form-create-customer.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  apiName = 'customersService';

  $newCustomerSelected = new Subject<string>();

  create = (input: CustomersCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomersDto>(
      {
        method: 'POST',
        url: '/api/customers-service/customer',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/customers-service/customer/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomersDto>(
      {
        method: 'GET',
        url: `/api/customers-service/customer/${id}`,
      },
      { apiName: this.apiName, ...config }
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/customers-service/customer/download-token',
      },
      { apiName: this.apiName, ...config }
    );

  getList = (input: GetCustomerInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomersDto>>(
      {
        method: 'GET',
        url: '/api/customers-service/customer',
        params: {
          filterText: input.filter,
          name: input.name,
          levelId: input.levelId,
          customerStatusId: input.customerStatusId,
          language: input.language,
          state: input.state,
          idHubspot: input.idHubspot,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          isPaginated: input.isPaginated,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getListAsExcelFile = (input: CustomersExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/customers-service/customer/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config }
    );

  update = (id: string, input: CustomersUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomersDto>(
      {
        method: 'PUT',
        url: `/api/customers-service/customer/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  getListWithNavigationProperties = (input: GetCustomerInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomerWithNavigationPropertiesDto>>(
      {
        method: 'GET',
        url: '/api/customers-service/customer/customers-user',
        params: {
          filterText: input.filterText,
          name: input.name,
          parentId: input.parentId,
          levelId: input.levelId,
          customerStatusId: input.customerStatusId,
          language: input.language,
          state: input.state,
          idHubspot: input.idHubspot,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getUserLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupUserDto<string>>>(
      {
        method: 'GET',
        url: '/api/customers-service/customer/user-lookup',
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config }
    );

  getCustomerByUsername = (userName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomersDto>(
      {
        method: 'GET',
        url: `/api/customers-service/customer/customer-username/`,
        params: { userName },
      },
      { apiName: this.apiName, ...config }
    );

  getCustomerByUserId = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomersDto>(
      {
        method: 'GET',
        url: `/api/customers-service/customer/profile/`,
        params: { userId },
      },
      { apiName: this.apiName, ...config }
    );

  constructor(private restService: RestService) {}

  customCreate = (data: CreateCustomer, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomersDto>(
      {
        method: 'POST',
        url: '/api/customers-service/customer/create',
        body: data,
      },
      { apiName: this.apiName, ...config }
    );

  checkNameExists = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/customers-service/customer/name-exists/${name}`,
        params: { name },
      },
      { apiName: this.apiName, ...config }
    );

  checkEmailExists = (email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/customers-service/customer/email-exists/${email}`,
        params: { email },
      },
      { apiName: this.apiName, ...config }
    );

  checkNameWithIdExists = (name: string, id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/customers-service/customer/name-exists-id/${name}/${id}`,
        params: { name },
      },
      { apiName: this.apiName, ...config }
    );

  getSharedLink = (missionId: string, customerId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SharedLinkDto[]>(
      {
        method: 'POST',
        url: `/api/customers-service/sharedlink/list`,
        body: {
          missionId,
          customerId,
          active: true,
        },
      },
      { apiName: this.apiName, ...config },
    );

  updateDaysSharedLink = (sharedLinkId: string, days: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SharedLinkDto>(
      {
        method: 'PUT',
        url: `/api/customers-service/sharedlink/update`,
        params: {
          sharedLinkId,
          days,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getSharedLinkData = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SharedLinkDto>(
      {
        method: 'GET',
        url: `/api/customers-service/sharedlink`,
        params: { id },
      },
      { apiName: this.apiName, ...config },
    );

  createSharedLink = (
    missionId: string,
    customerId: string,
    days: number,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, SharedLinkDto>(
      {
        method: 'POST',
        url: `/api/customers-service/sharedlink/create`,
        body: {
          missionId,
          customerId,
          days,
        },
      },
      { apiName: this.apiName, ...config },
    );
    
  announceCustomSelected(customerId: string) {
    this.$newCustomerSelected.next(customerId);
  }
}
