import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderFormPackageModel } from 'projects/flyguys/src/app/components/orders/model/order-form-package-model';
import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';

import { OrderFormModel } from '../../components/orders/model/order-form.model';
import { OrderFormDeliverableModel } from '../../components/orders/model/order-form-deliverable.model';
import { OrderFormDeliverableFieldModel } from '../../components/orders/model/order-form-deliverable-field.model';
import { MatDialog } from '@angular/material/dialog';
import { DeliverableAttributesListComponent } from '../deliverable-attributes-list/deliverable-attributes-list.component';

import { Subscription } from 'rxjs';
import { DeliverableAttribute } from '../deliverable-attributes-list/deliverable-attribute.interface';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PackageComponent implements OnInit, OnDestroy {
  private readonly quantityControl = 'quantity';
  private readonly defaultQuantity = 1;
  private isInitialSelection = false;

  @Input() package: OrderFormPackageModel;
  @Input() disableChildren = false;

  @Input() genericPackage = false;

  @Input() model: OrderFormModel;

  originalQuantityDeliverable: number;
  kmlDataArray: KmlDataInfoPkg[] = [];
  detailedAtt: AttributesInfo[] = [];

  formPackage: FormGroup;

  incompleteConfiguration: boolean;
  missingRequiredConfiguration: boolean;
  editingPrice: boolean;

  private subscriptions = new Subscription();

  constructor(
    private dialogService: MatDialog,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.formPackage = FormHelpers.buildValidatorsPackage();

    const existingPackageIndex = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (existingPackageIndex !== -1) {
      this.package.selected = true;
      this.formPackage
        .get('quantity')
        .setValue(this.model.orderDetail.packagesSelected[existingPackageIndex].quantity);

      this.package.deliverables =
        this.model.orderDetail.packagesSelected[existingPackageIndex].deliverables;
    } else {
      this.package.quantity = 1;
    }
  }

  selectPackage() {
    this.isInitialSelection = !this.package?.selected;

    if (this.package) {
      this.package.deliverables.forEach(deliverable => {
        if (deliverable.fields && Array.isArray(deliverable.fields)) {
          const kmlFields = deliverable.fields.filter(field => field.typeCode.includes('KML'));
          const otherFields = deliverable.fields.filter(field => !field.typeCode.includes('KML'));
          deliverable.fields = [...otherFields, ...kmlFields];
        }
      });
    }

    const existingPackageIndex = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (existingPackageIndex !== -1) {
      this.model.orderDetail.packagesSelected[existingPackageIndex].quantity =
        this.package.quantity;
    } else {
      this.package.selected = true;
      this.package.genericPackage = this.genericPackage;
      this.model.orderDetail.packagesSelected.push(this.package);
    }

    this.updatePackageTotals();
    this.model.buildDetails();
    this.editAttributes(false);
    this.validateRequiredConfigurations();
  }

  updatePrice() {
    const indexEdit = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (indexEdit !== -1) {
      const packageselected = this.model.orderDetail.packagesSelected[indexEdit];
      packageselected.packagePrice = String(this.formPackage.get('price').value);
      this.package.packagePrice = packageselected.packagePrice;
      this.editingPrice = false;
      this.onQuantityChange(null);
    }
  }

  unselectPackage() {
    this.package.selected = false;
    this.package.incompletePackage = false;
    this.clearAttributes();
    this.clearKmlData(this.package);
    const indexRemove = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );
    if (indexRemove !== -1) {
      this.model.orderDetail.packagesSelected.splice(indexRemove, 1);
    }
    this.updatePackageTotals();
    this.model.buildDetails();
    this.model.orderDetail.packagesSelected = [...this.model.orderDetail.packagesSelected];
  }

  clearKmlData(pkg: OrderFormPackageModel) {
    let packageId = pkg?.packageId;
    if (this.kmlDataArray.length > 0) {
      this.kmlDataArray = this.kmlDataArray.filter(kmlD => kmlD.packageId !== packageId);
    }
  }

  onQuantityChange(event: any) {
    this.updatePackageTotals();
    this.model.buildDetails();
    this.validateConfigurations();
    this.validateRequiredConfigurations();
  }

  onQuantityBlur() {
    const quantityControl = this.formPackage.get('quantity');
    if (!quantityControl.value) {
      quantityControl.setValue(1);
    }
  }

  editPrice() {
    this.editingPrice;
  }

  updatePackageTotals() {
    const quantityControl = this.formPackage.get('quantity');
    const indexEdit = this.model.orderDetail.packagesSelected.findIndex(
      item => item.packageId === this.package.packageId,
    );

    if (indexEdit !== -1) {
      const packageselected = this.model.orderDetail.packagesSelected[indexEdit];

      this.formPackage.get('price').setValue(Number(packageselected.packagePrice));

      packageselected.quantity = quantityControl.value;
      packageselected.packageSubtotal =
        packageselected.quantity * Number(packageselected.packagePrice);
      packageselected.packageSubtotal = Math.abs(
        Number(packageselected.packageSubtotal.toFixed(2)),
      );
      packageselected.deliverables.forEach((d: OrderFormDeliverableModel) => {
        const quantityToAdd = packageselected.quantity;
        const numericQuantityToAdd = Number(quantityToAdd);
        const originalQuantity = d.originalquantity ?? 1;
        let total = Number(originalQuantity * numericQuantityToAdd);
        d.quantity = total;
        d.subTotal = d.quantity * d.actualPrice;
        d.subTotal = Math.abs(Number(d.subTotal.toFixed(2)));
      });
    }
  }

  editAttributes(isEdition: boolean) {
    this.incompleteConfiguration = false;

    if (!isEdition) {
      this.package.deliverables.forEach(x => {
        x.sameConfiguration = true;
      });
    }

    let kmlInfo: KmlDataInfoPkg[] = [];
    if (this.kmlDataArray.length > 0) {
      this.kmlDataArray.forEach(x => {
        const uniqueStringValues = new Set<string>();
        const cleanedKmlField = x.kmlField.filter(field => {
          if (!uniqueStringValues.has(field.stringValue)) {
            uniqueStringValues.add(field.stringValue);
            return true;
          }
          return false;
        });
        x.kmlField = cleanedKmlField;
      });
      kmlInfo = this.kmlDataArray.filter(x => x.packageId == this.package.packageId);
    }
    const dialogData = {
      title: 'Add Deliverables Attributes',
      deliverableList: this.package.deliverables,
      quantity: this.getCurrentQuantity(),
      isEdition: isEdition,
      pckgeKmlInfo: kmlInfo,
    };

    const dialogRef = this.dialogService.open(DeliverableAttributesListComponent, {
      width: '1150px',
      data: dialogData,
      disableClose: true,
    });

    const dialogSub = dialogRef
      .afterClosed()
      .subscribe(
        (result: {
          attributesData: DeliverableAttribute[];
          fieldUpload: OrderFormDeliverableFieldModel[];
          kmlFieldData: OrderFormDeliverableFieldModel[];
          kmlInfoUplaod: KmlDataInfoPkg[];
          kmlFieldRemoved: KmlDataInfoPkg[];
        }) => {
          if (result === null) {
            if (this.isInitialSelection) {
              this.unselectPackage();
            }
            return;
          }

          this.isInitialSelection = false;

          let packageUpdated = this.model.orderDetail.packagesSelected.find(
            item => item.packageId === this.package.packageId,
          );

          if (packageUpdated) {
            for (let deliverable of packageUpdated?.deliverables) {
              let attributeValues = result?.attributesData.find(
                x => x.productId == deliverable.productId,
              );

              if (!attributeValues) continue;

              deliverable.sameConfiguration = attributeValues?.sameConfiguration;
              deliverable.fields = [...attributeValues.fields];

              let detailedAttributes = attributeValues?.detailedAttributes.map(x => [...x]);
            }
          }
          let deliverables = [];
          if (result && result.attributesData) {
            deliverables = result.attributesData.filter(attribute =>
              attribute.fields?.some(field =>
                field.typeCode.toLowerCase().includes('kml'.toLowerCase()),
              ),
            );
          }

          if (result?.attributesData.length > 0) {
            let detailAttribute = this.detailedAtt.find(x => x.packageId == this.package.packageId);
            if (detailAttribute) {
              detailAttribute.deliverableAttribute = [...result.attributesData];
            } else {
              this.detailedAtt.push({
                packageId: this.package.packageId,
                deliverableAttribute: [...result.attributesData],
              });
            }
          } else {
            if (result && result.attributesData) {
              this.detailedAtt.push({
                packageId: this.package.packageId,
                deliverableAttribute: [...result.attributesData],
              });
            }
          }

          this.package.deliverables.forEach(pkg => {
            if (pkg.sameConfiguration) {
              if (deliverables.length > 0) {
                deliverables.forEach(del => {
                  let dataByProductId = result.kmlInfoUplaod.find(
                    r => r.productId == del.productId,
                  );

                  if (dataByProductId !== undefined) {
                    let existingKmlData = this.kmlDataArray.find(
                      kml => kml.productId === del.productId,
                    );

                    if (existingKmlData) {
                      existingKmlData.kmlField = [
                        ...existingKmlData.kmlField,
                        ...result.kmlFieldData,
                        ...dataByProductId.kmlField,
                      ];
                    } else {
                      this.kmlDataArray.push({
                        deliverableId: del?.deliverableId,
                        productId: del?.productId,
                        packageId: this.package.packageId,
                        kmlField: [...result.kmlFieldData, ...dataByProductId.kmlField],
                      });
                    }
                  }
                });
              }
              if (result && result.kmlFieldRemoved) {
                deliverables.forEach(del => {
                  let dataByProductId = result.kmlFieldRemoved.find(
                    r => r.productId === del.productId,
                  );
                  if (dataByProductId) {
                    let existingKmlData = this.kmlDataArray.find(
                      kml => kml.productId === del.productId,
                    );
                    if (existingKmlData) {
                      let removedFileValues = new Set(
                        dataByProductId.kmlField.map(da => da.fileValue),
                      );
                      existingKmlData.kmlField.forEach(ex => {
                        if (removedFileValues.has(ex.fileValue)) {
                          ex.stringValue = '';
                          ex.fileValue = '';
                        }
                      });
                    }
                  }
                });
              }
            } else {
              result?.attributesData?.forEach(x => (pkg.detailedAttributes = x.detailedAttributes));
              if (deliverables.length > 0) {
                deliverables.forEach((dv, indexDel) => {
                  if (dv.productId === pkg.productId) {
                    dv.detailedAttributes.forEach((dvDet, index) => {
                      result.kmlInfoUplaod.forEach(rKml => {
                        let rKmlFilter = rKml.kmlField.filter(rKmlField =>
                          rKmlField.fieldControlName.includes(`ctrl-${indexDel}-${index}`),
                        );

                        if (rKmlFilter.length > 0) {
                          let kmlExists = this.kmlDataArray.find(kmlD =>
                            kmlD.kmlField.some(
                              kmlField =>
                                kmlField.fieldControlName === rKmlFilter[0].fieldControlName,
                            ),
                          );

                          if (kmlExists) {
                            kmlExists.kmlField = [...kmlExists.kmlField, ...rKmlFilter];
                          } else {
                            this.kmlDataArray.push({
                              deliverableId: pkg?.deliverableId,
                              productId: dv?.productId,
                              packageId: this.package.packageId,
                              kmlField: rKmlFilter,
                            });
                          }
                        }
                      });
                    });
                  }
                });
              }
              if (result && result.kmlFieldRemoved) {
                deliverables.forEach(del => {
                  let dataByProductId = result.kmlFieldRemoved.find(
                    r => r.productId === del.productId,
                  );
                  if (dataByProductId) {
                    let existingKmlData = this.kmlDataArray.find(
                      kml => kml.productId === del.productId,
                    );
                    if (existingKmlData) {
                      const removedFileValues = new Set(
                        dataByProductId.kmlField.map(da => da.fileValue),
                      );
                      existingKmlData.kmlField.forEach(ex => {
                        if (removedFileValues.has(ex.fileValue)) {
                          ex.stringValue = '';
                          ex.fileValue = '';
                        }
                      });
                    }
                  }
                });
              }
            }
          });
        },
      );
    this.subscriptions.add(dialogSub);
  }

  clearAttributes() {
    this.package.deliverables.forEach((d: OrderFormDeliverableModel) => {
      d.fields.forEach((f: OrderFormDeliverableFieldModel) => {
        f.numericValue = 0;
        f.stringValue = '';
        f.boolValue = false;
      });
    });

    this.setQuantity(this.defaultQuantity);
    this.package.quantity = this.defaultQuantity;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private getCurrentQuantity(): number {
    return this.formPackage.get(this.quantityControl).value;
  }

  private setQuantity(value: number): void {
    this.formPackage.get(this.quantityControl).setValue(value);
  }

  private validateConfigurations(): void {
    this.incompleteConfiguration = false;

    for (let deliverable of this.package.deliverables) {
      if (deliverable.sameConfiguration) continue;

      this.incompleteConfiguration = deliverable.quantity > deliverable.detailedAttributes?.length;

      if (this.incompleteConfiguration) break;
    }
  }

  private validateRequiredConfigurations(): void {
    this.missingRequiredConfiguration = false;

    for (let deliverable of this.package.deliverables) {
      if (deliverable.sameConfiguration) {
        let requiredAttributes = deliverable.fields?.filter(x => x.isRequired) || [];

        this.missingRequiredConfiguration = !!requiredAttributes.find(
          x => x.stringValue == undefined || x.stringValue == '',
        );

        if (this.missingRequiredConfiguration) break;
      } else {
        if (!deliverable.detailedAttributes) deliverable.detailedAttributes = [];

        let hasRequiredAttr = !!deliverable.fields?.find(x => x.isRequired);

        if (hasRequiredAttr && deliverable.quantity > deliverable.detailedAttributes.length) {
          this.missingRequiredConfiguration = true;
          break;
        }

        for (let listOfAttributes of deliverable.detailedAttributes) {
          let requiredAttributes = listOfAttributes?.filter(x => x.isRequired) || [];

          this.missingRequiredConfiguration = !!requiredAttributes.find(
            x => x.stringValue == undefined || x.stringValue == '',
          );

          if (this.missingRequiredConfiguration) break;
        }

        if (this.missingRequiredConfiguration) break;
      }

      if (this.missingRequiredConfiguration) break;
    }

    this.package.incompletePackage = this.missingRequiredConfiguration;

    this.changeDetector.detectChanges();
  }
}

export interface KmlDataInfoPkg {
  productId?: string;
  packageId?: string;
  deliverableId?: string;
  kmlField: OrderFormDeliverableFieldModel[];
}

export interface AttributesInfo {
  packageId?: string;
  deliverableAttribute?: DeliverableAttribute[];
}
