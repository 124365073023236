import { AfterViewInit, Component, Input, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { OrderFormSiteModel } from '../model/order-form-site.model';
import { FlyguysMapComponent, FlyguysMapMarker } from '@flyguys/map';
import { formatTimeToAmPm } from '../../../utils/time';
import { Subscription } from 'rxjs';
import { MapReloadService } from '../services/map-reload.service';
import { ABP, LocalizationService, PagedResultDto } from '@abp/ng.core';
import {
  CalendarControl,
  CheckBoxControl,
  Control,
  DropDownListControl,
  enumState,
  InputControl,
  TextAreaControl,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { OrderFormModel } from '../model/order-form.model';
import {
  GetValuesCustomerAttributesInput,
  ValuesCustomerAttributeCreateDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import {
  CustomerAttributeWithNavigationPropertiesDto,
  GetCustomerAttributesInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/relationals';
import { CustomerAttributeService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/relationals';

@Component({
  selector: 'app-mission-overview',
  templateUrl: './mission-overview.component.html',
  styleUrls: ['./mission-overview.component.scss'],
})
export class MissionOverviewComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  @Input() mission!: OrderFormSiteModel;
  @Input() orderModel: OrderFormModel;

  private subscriptions = new Subscription();

  columns = [
    { prop: 'customerDescription', name: 'Contact from' },
    { prop: 'name', name: 'Name' },
    { prop: 'lastname', name: 'Last Name' },
    { prop: 'email', name: 'Email' },
    { prop: 'number', name: 'Number' },
    { prop: 'shareData', name: 'Share Data' },
    { name: 'Actions' },
  ];

  customerAttributes: PagedResultDto<CustomerAttributeWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };

  controls: Control<string>[] = [];

  constructor(
    private mapReloadService: MapReloadService,
    private readonly localizationService: LocalizationService,
    public readonly customerAttributeService: CustomerAttributeService,
  ) {
    const subsMapsReload = this.mapReloadService.reloadObservable.subscribe(() => {
      this.updateMap();
    });

    this.subscriptions.add(subsMapsReload);
  }

  get captureTime(): string {
    return formatTimeToAmPm(this.mission.captureTime);
  }

  private updateMap(): void {
    if (this.mission.kmlFiles?.length > 0) {
      setTimeout(() => {
        this.map.loadKMLLayer(this.mission.kmlFiles[0]?.path);
        this.updateMapMarker();
      }, 500);
    } else {
      this.removeKmlFile();
    }
  }

  ngOnInit(): void {
    this.getCustomerAttribute();
  }

  ngAfterViewInit(): void {
    this.updateMap();
  }

  removeKmlFile(): void {
    setTimeout(() => {
      this.map.removeKMLLayer();
      this.updateMapMarker();
    }, 500);
  }

  updateMapMarker() {
    const currentLat = this.mission.loclatitude;
    const currentLng = this.mission.loclongitude;
    if (currentLat && currentLng) {
      this.map.clearMarkers();
      const newMarker: FlyguysMapMarker = {
        id: 1,
        lat: parseFloat(currentLat),
        lng: parseFloat(currentLng),
      };
      this.map.addMarker(newMarker);
    }
  }

  hasKmlFile(): boolean {
    return this.mission.kmlFiles?.length > 0;
  }

  hasAttachmentsFiles(): boolean {
    return this.mission.filesAttachments?.length > 0;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private getCustomerAttribute(): void {
    if (this.orderModel && this.mission.customAttributes) {
      const query = {} as ABP.PageQueryParams;
      const customerAttributeFilter = {
        state: enumState.Enabled,
        customersId: this.orderModel.customerId,
      } as GetCustomerAttributesInput;

      this.customerAttributeService
        .getList({
          ...query,
          ...customerAttributeFilter,
          filterText: query.filter,
        })
        .subscribe(res => {
          this.customerAttributes = res;
          this.generateFormCustomerAttributeV1(this.mission.customAttributes);
        });
    }
  }
  generateFormCustomerAttributeV1(customAttributes: ValuesCustomerAttributeCreateDto[]) {
    let _values: Array<{ key: string; value: string }> = [];
    this.controls = [];

    this.customerAttributes.items.forEach(element => {
      let _value = customAttributes
        .filter(x => x.customerAttributeId)
        .find(v => v.customerAttributeId == element.customerAttribute.id)?.value;

      if (!_value) {
        _value = '';
      }

      switch (element.customerAttribute.controlTypeDescription.toUpperCase()) {
        case 'CHECKBOX': {
          let control: Control<string> = new CheckBoxControl();

          control.key = element.customerAttribute.id;
          control.label = element.customerAttribute.displayName;
          control.required = element.customerAttribute.isRequired ? true : false;
          control.type = 'checkbox';

          let _valueCheckBox: boolean;

          if (_value == 'false') {
            _valueCheckBox = false;
          } else {
            _valueCheckBox = true;
          }

          control.valueCheckBox = _value == '' ? false : _valueCheckBox;

          this.controls.push(control);
          break;
        }
        case 'DROPDOWNLIST': {
          let control: Control<string> = new DropDownListControl();

          let _valuesList = element.customerAttribute.listValues;
          let _aValueslist = _valuesList.split(',');

          _aValueslist.forEach(item => {
            _values.push({ key: item, value: item });
          });

          control.key = element.customerAttribute.id;
          control.label = element.customerAttribute.displayName;
          control.required = element.customerAttribute.isRequired ? true : false;
          control.options = _values;
          control.value = _value;

          this.controls.push(control);
          break;
        }
        case 'CALENDAR': {
          let control: Control<string> = new CalendarControl();
          control.key = element.customerAttribute.id;
          control.label = element.customerAttribute.displayName;
          control.required = element.customerAttribute.isRequired ? true : false;
          control.value = _value;

          this.controls.push(control);
          break;
        }
        case 'INPUT':
        case 'NUMBER':
        case 'TEXT': {
          let control: Control<string> = new InputControl();

          control.key = element.customerAttribute.id;
          control.label = element.customerAttribute.displayName;
          control.required = element.customerAttribute.isRequired ? true : false;
          control.type =
            element.customerAttribute.customerAttributeTypeDescription.toUpperCase() == 'TEXT'
              ? 'text'
              : 'number';
          if (element.customerAttribute.maxTextLength != 0) {
            control.maxLength = element.customerAttribute.maxTextLength;
          } else {
            control.maxLength = null;
          }
          control.value = _value;

          this.controls.push(control);
          break;
        }
        case 'TEXT AREA': {
          let control: Control<string> = new TextAreaControl();

          control.key = element.customerAttribute.id;
          control.label = element.customerAttribute.displayName;
          control.required = element.customerAttribute.isRequired ? true : false;
          if (element.customerAttribute.maxTextLength != 0) {
            control.maxLength = element.customerAttribute.maxTextLength;
          } else {
            control.maxLength = null;
          }
          control.value = _value;
          this.controls.push(control);
          break;
        }
      }
    });
  }

  getCheckboxValueDescription(value: boolean): string {
    if (value) {
      return this.localizationService.instant('missionsService::Yes');
    }

    return this.localizationService.instant('missionsService::No');
  }

  getCalendarValueDescription(value: string): string {
    if (value) {
      const dateObj = new Date(value);
      return `${dateObj.getMonth() + 1}/${dateObj.getUTCDate()}/${dateObj.getFullYear()}`;
    }

    return '---';
  }

  getDefaultValueDescription(value: string): string {
    if (value) {
      return value;
    }

    return '---';
  }
}
