<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span class="site-address"> {{ mission.missionName }} </span>
      <span class="site-number">Site {{ mission.number }}</span>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="site-information container p-0">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="site-info-group">
          <h3>Mission Name</h3>
          <p>{{ mission.missionName }}</p>
        </div>

        <div class="site-info-group">
          <h3>Mission Summary</h3>
          <div class="flg-editor-output" [innerHTML]="mission.missionSummary"></div>
        </div>
        <div class="site-info-group" *ngIf="controls?.length > 0">
          <h2>{{ 'missionsService::CustomerDefinedFields' | abpLocalization }}</h2>
          <div class="description flg-editor-output">
            <div class="row">
              <div *ngFor="let control of controls" class="col-lg-12 col-md-12 col-sm-12 mb-1">
                <ng-container [ngSwitch]="control.controlType">
                  <ng-container *ngSwitchCase="'CHECKBOX'">
                    <h3>{{ control.label }}</h3>
                    <p>{{ getCheckboxValueDescription(control.valueCheckBox) }}</p>
                  </ng-container>
                  <ng-container *ngSwitchCase="'CALENDAR'">
                    <h3>{{ control.label }}</h3>
                    <p>{{ getCalendarValueDescription(control.value) }}</p>
                  </ng-container>
                  <ng-container *ngSwitchCase="'TEXTAREA'">
                    <h3 class="subtitle">{{ control.label }}:</h3>
                    <div
                      class="description flg-editor-output"
                      [innerHTML]="control.value?.length < 1 ? '---' : control.value"
                    ></div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <h3>{{ control.label }}</h3>
                    <p>{{ getDefaultValueDescription(control.value) }}</p>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <h2>Capture Date & Time</h2>

        <div class="site-info-group d-inline-block mtext-break w-33">
          <h3>Capture Date</h3>
          <p>{{ (mission.captureDate | date: 'shortDate') || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <h3>Capture Time</h3>
          <p>{{ captureTime || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block w-33">
          <h3>Repeats</h3>
          <p>{{ mission.recurrenceDescription || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-33">
          <!-- This should be an enum. Hardcoding value from order-site.component -->
          <h3>Capture Date Must Be</h3>
          <p>{{ mission.captureDateMustDescription || 'N/A' }}</p>
        </div>

        <!-- <div class="site-info-group d-inline-block w-33">
          @TODO Uncomment if merged from UAT
          This should be an enum. Hardcoding value from order-site.component
          <h3>Flexible Capture Time</h3>
          <p>{{ mission.flexibleCaptureId === '1' ? 'No' : 'Yes' }}</p>
        </div> -->

        <h2>Deliverables Aspects</h2>

        <div class="site-info-group">
          <h3>Deliverable Due Date</h3>
          <p>{{ (mission.deliveryDate | date: 'shortDate') || 'N/A' }}</p>
        </div>

        <div class="site-info-group">
          <h3>Pilots Upload Data</h3>
          <div *ngIf="mission.externalLinkName?.length > 0">
            <a [href]="mission.externalLink" target="_blank">{{ mission.externalLinkName }}</a>
          </div>
          <div *ngIf="mission.externalLinkName?.length < 1">{{ mission.uploadDataLocation }}</div>
        </div>

        <!-- <div class="site-info-group">
          @TODO Uncomment if merged from UAT
          <h3>Capture Deliverables</h3>
          <p>{{ mission.deliveryAllSameTime ? 'All at the same time' : 'No' }}</p>
        </div> -->

        <div class="site-info-group">
          <h3>Deliverable Notes</h3>
          <div
            class="flg-editor-output"
            [innerHTML]="mission.deliveryNotes || 'N/A'"
            external-link
          ></div>
        </div>

        <div class="site-info-group">
          <h3>Data Uploading / Delivery Instructions</h3>
          <div
            class="flg-editor-output"
            [innerHTML]="mission.uploadingDataInstruction || 'N/A'"
            external-link
          ></div>
        </div>

        <h2>Site Contacts Information</h2>

        <app-order-contact-table
          class="d-block mb-4"
          [contacts]="mission.siteContacts"
          [columns]="columns"
          [disableActions]="true"
        ></app-order-contact-table>

        <h2>Location</h2>

        <div class="site-info-group">
          <h3>Site Name</h3>
          <p>{{ mission.locSiteName || 'N/A' }}</p>
        </div>

        <div class="site-info-group">
          <h3>Address</h3>
          <p>{{ mission.locAddress || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>Country</h3>
          <p>{{ mission.locCountryDescription || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>State</h3>
          <p>{{ mission.locStateDescription || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>City</h3>
          <p>{{ mission.locCity || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-25">
          <h3>Zip Code</h3>
          <p>{{ mission.locZipCode || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Latitude</h3>
          <p>{{ mission.loclatitude || 'N/A' }}</p>
        </div>

        <div class="site-info-group d-inline-block text-break w-50">
          <h3>Longitude</h3>
          <p>{{ mission.loclongitude || 'N/A' }}</p>
        </div>

        <div class="site-info-group">
          <h3>Airspace Classification</h3>
          <p>{{ mission.locAirSpaceClasfDescription || 'N/A' }}</p>
        </div>

        <div class="site-info-group" *ngIf="hasKmlFile()">
          <h3>KML File</h3>
          <div class="btn btn-gray" [ngClass]="{ 't-ml-8': i > 0 }">
            {{ mission.kmlFiles[0].name }}
          </div>
        </div>

        <flg-map
          [usesKML]="true"
          [options]="{
            disableDefaultUI: true,
            mapTypeId: 'satellite',
          }"
        ></flg-map>
      </div>
    </div>

    <div class="site-info-group" *ngIf="hasAttachmentsFiles()">
      <h2>Attachments</h2>
      <label for="attachDocumentation" class="label-order"
        >Attach reference documentation (Sample Images, Authorization forms, other)</label
      >
    </div>
    <ng-container *ngFor="let doc of mission.filesAttachments; let i = index">
      <div *ngIf="(i + 1) % 6 === 0 || i + 1 === mission.filesAttachments.length">
        <div class="d-flex w-100"></div>
      </div>
      <div class="btn btn-gray me-2 mt-2">
        {{ doc.name }}
      </div>
      <div
        *ngIf="(i + 1) % 6 === 0 || i + 1 === mission.filesAttachments.length"
        class="w-100"
      ></div>
    </ng-container>

    <div class="site-info-group mt-3">
      <h3>Additional Notes</h3>
      <div class="flg-editor-output" [innerHTML]="mission.aditionalNotes || 'N/A'"></div>
    </div>

    <div class="site-info-group mt-3">
      <h3>SLA</h3>
      <app-order-sla-table [slas]="mission.sla"></app-order-sla-table>
    </div>
  </div>
</mat-expansion-panel>
