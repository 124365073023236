<h5 mat-dialog-title class="flg-modal flg-modal__title">
  <span>Chat with {{ selectedContact.name }} {{ selectedContact.surname }}</span>
  <span class="material-symbols-outlined ms-auto" mat-dialog-close="true"> close </span>
</h5>

<mat-dialog-content class="flg-modal flg-modal__body">
  <div class="col-12 col-md-12 col-lg-12 d-flex align-items-stretch">
    <div class="w-100">
      <ng-scrollbar visibility="hover" style="height: 400px">
        <div id="chat-conversation-wrapper" class="chat-box mb-2">
          <div class="chat-box-container" id="chat-conversation">
            <ng-container
              *ngFor="
                let message of selectedContactMessages;
                let i = index;
                trackBy: trackByMessageDate
              "
            >
              <div
                *ngIf="selectedContactMessages.length - unreadMessageCount === i"
                class="row justify-content-center unread-message-count-badge-wrapper"
              >
                <h3>
                  <span class="badge badge-info">
                    <ng-container *ngIf="unreadMessageCount === 1; else moreThanOne">
                      {{ 'Chat::YouHaveAnUnreadMessage' | abpLocalization }}
                    </ng-container>

                    <ng-template #moreThanOne>
                      {{
                        'Chat::YouHave{0}UnreadMessages' | abpLocalization: '' + unreadMessageCount
                      }}
                    </ng-template>
                  </span>
                </h3>
              </div>
              <div
                *ngIf="{ isSender: message.side === chatMessageSide.Sender } as data"
                class="media w-75 mw-65 w-lp-auto mb-2"
                [class.ms-auto]="data.isSender"
                #chatMessage
              >
                <div class="media-body position-relative">
                  <div
                    class="media-container py-2 px-2 py-lg-2 px-lg-3 mb-1 {{
                      data.isSender ? 'media-container__sender' : 'media-container__reciever'
                    }}"
                  >
                    <p
                      class="message-text text-small mb-0 lh-base {{
                        data.isSender ? 'text-white' : 'text-dark'
                      }}"
                      [innerHTML]="message.message"
                    ></p>
                  </div>
                  <p class="message-date m-0 {{ data.isSender ? 'right' : 'left' }}">
                    {{
                      getDateInLocalTime(message.messageDate)
                        | date: getDateFormat(message.messageDate)
                    }}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flg-modal flg-modal__actions">
  <div *ngIf="selectedContact?.userId" id="send-message-form" class="bg-light m-0">
    <div class="ps-3 pe-3 pt-3">
      <textarea
        id="chat-message-box"
        name="message"
        type="text"
        [placeholder]="'Chat::TypeMessage' | abpLocalization"
        class="form-control rounded bg-white"
        [(ngModel)]="message"
        (keydown.enter)="sendWithEnter($event)"
      ></textarea>
    </div>
    <div class="d-flex align-items-center justify-content-between p-3">
      <div class="d-flex align-items-center form-check mt-2">
        <input
          type="checkbox"
          class="form-check-input border shadow-sm me-1"
          id="send-on-enter"
          [(ngModel)]="sendOnEnter"
        />
        <label class="form-check-label" for="send-on-enter">{{
          'Chat::SendOnEnter' | abpLocalization
        }}</label>
      </div>

      <button
        [disabled]="!message"
        (click)="send()"
        id="send-message-button"
        type="button"
        class="btn btn-primary px-3"
      >
        {{ 'Chat::Send' | abpLocalization }}
        <i *ngIf="!loading; else loadingIcon" class="fa fa-paper-plane ms-2" aria-hidden="true"></i>

        <ng-template #loadingIcon>
          <i class="fas fa-spinner fa-spin ms-2" aria-hidden="true"></i>
        </ng-template>
      </button>
    </div>
  </div>
</mat-dialog-actions>
