<ngx-spinner
  bdColor="rgba(245,245,245,0.7)"
  size="default"
  color="#bc5a2e"
  type="ball-square-clockwise-spin"
  [fullScreen]="true"
  [zIndex]="1111"
>
  <p style="color: #bc5a2e">Loading - Getting Files</p>
</ngx-spinner>
<div class="bg-white row">
  <div class="pt-4 first-bar"></div>
  <div class="second-bar mb-3">
    <img src="assets/images/FGWhite.png" alt="logo" class="offset-1 mt-3 mb-3" />
  </div>

  <div class="text-center pb-5" *ngIf="error">
    <span class="material-symbols-outlined warning-simbol"> warning </span>
    <br />
    <h1>Link Has Expired</h1>
    <h4>Contact the person who sent you the link to grant you access again.</h4>
  </div>

  <div class="col-10 offset-1" *ngIf="!error">
    <h2>{{ missionFlow?.missionName }}</h2>
    <div class="d-flex">
      <div class="w-100 me-3">
        <app-info-card-with-action
          [icon]="'person_raised_hand'"
          [title]="'missionsService::Customer' | abpLocalization"
          [actionIconVisible]="false"
          [height]="'100px'"
        >
          <app-subtitle-description
            [subtitle]="missionFlow?.customerName"
          ></app-subtitle-description>
        </app-info-card-with-action>
      </div>

      <div class="w-100 me-3">
        <app-info-card-with-action
          [icon]="'numbers'"
          [title]="'missionsService::Job ID' | abpLocalization"
          [actionIconVisible]="false"
          [height]="'100px'"
        >
          <app-subtitle-description [subtitle]="missionFlow?.jobId"></app-subtitle-description>
        </app-info-card-with-action>
      </div>

      <div class="w-100">
        <app-info-card-with-action
          [icon]="'calendar_today'"
          [title]="'missionsService::Capture Date' | abpLocalization"
          [actionIconVisible]="false"
          [height]="'100px'"
        >
          <app-subtitle-description
            [subtitle]="missionFlow?.captureDate + 'z' | date: 'MM/dd/yyyy | hh:mm a'"
          ></app-subtitle-description>
        </app-info-card-with-action>
      </div>
    </div>
    <div class="d-flex">
      <div class="col-sm-12">
        <app-info-card-with-action
          [icon]="'distance'"
          [title]="'missionsService::Address' | abpLocalization"
          [actionIconVisible]="false"
          [height]="'100px'"
        >
          <app-subtitle-description
            [subtitle]="orderRequest?.location?.address"
          ></app-subtitle-description>
        </app-info-card-with-action>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <h2>Deliverables on this Mission</h2>

      <div class="d-flex align-items-center gap-2">
        <button
          mat-button
          type="button"
          class="btn btn-primary mx-8px ng-star-inserted text-white"
          (click)="downloadAllFiles()"
        >
          <mat-icon>download</mat-icon>
          Download All Files
        </button>
      </div>
    </div>

    <div class="row col-12" *ngIf="!showFolder">
      <mat-card
        class="mat-mdc-card mdc-card mb-4 col-sm-3 col-md-3 d-flex card-tile-view card-item pb-2 me-2"
        *ngFor="let item of deliverables; let i = index"
      >
        <mat-card-content class="mat-mdc-card-content">
          <div class="row text-center">
            <div (click)="viewFiles(item)" class="folder">
              <img src="assets/images/logo/folder-logo.png" class="col-sm-4" />
              <br /><br />
              <span class="mt-15 d-inline-block">{{ item.deliverableName }}</span>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer class="mt-3">
          <div class="row">
            <div class="col-3 text-start ms-0 pe-0 ps-1">
              <span>
                {{ item.filesCount }}
                <span *ngIf="item.filesCount > 1 || item.filesCount == 0">
                  {{ 'FileManagement::Items' | abpLocalization }}
                </span>
                <span *ngIf="item.filesCount <= 1 && item.filesCount != 0">
                  {{ 'FileManagement::Item' | abpLocalization }}
                </span>
              </span>
            </div>
            <div class="col-4 text-end ms-0 pe-0 ps-1">{{ getItemSize(item.fileSize) }}</div>
            <div class="col-1 text-end ms-0 pe-0 ps-1"><span class="vr"></span></div>
            <div class="col-3 text-end ms-0 pe-0 ps-1">
              <span
                >{{ i + 1 }}<span class="font-weight-bold">/</span>{{ deliverables.length }}</span
              ><span></span>
            </div>
          </div>
        </mat-card-footer>
      </mat-card>
    </div>
    <div class="row col-12">
      <app-deliverable-files
        class="deliverable-files-content"
        *ngIf="showFolder"
        [class.d-none]="!showFolder"
        [missionFilter]="folderMissions"
        [deliverable]="selectedDeliverable"
        (nameInteracted)="goBack()"
        [deliverables]="deliverables"
        [isSharedLink]="true"
      ></app-deliverable-files>
    </div>
  </div>
</div>
