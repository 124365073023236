import { Injectable } from '@angular/core';
import {
  CreateDirectoryInput,
  DirectoryDescriptorService,
} from '@volo/abp.ng.file-management/proxy';
import { tap } from 'rxjs/operators';
import { mapRootIdToEmpty } from '../../services/directory-tree.service';
import { UpdateStreamService } from '../../services/update-stream.service';

@Injectable()
export class CreateFolderModalService {
  constructor(
    private service: DirectoryDescriptorService,
    private updateStream: UpdateStreamService,
  ) {}

  create(
    name: string,
    parent?: string,
    rootId: string = undefined,
    missionId?: string,
    deliverableId?: string,
    captureId?: string,
    orderDetailId?: string,
  ) {
    const parentId = mapRootIdToEmpty(parent || this.updateStream.currentDirectory);

    return this.service
      .create({
        name,
        parentId: mapRootIdToEmpty(rootId || parentId),
        extraProperties: {},
        missionId: missionId,
        deliverableId: deliverableId,
        captureId: captureId,
        orderDetailId: orderDetailId,
      })
      .pipe(tap(_ => this.updateStream.patchStore({ createdDirectory: parentId })));
  }

  checkFolderName = (input: CreateDirectoryInput) => this.service.checkFolderName(input);
}
