import { FilterConfig } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/filter-config.model';
import { FilterType } from '../../../../../../../flyguys/src/app/shared/grid-filters/models/filter-type.enum';

export const MissionsFilterColumns: FilterConfig = {
  conditions: [
    {
      column: 'missionStatusId',
      columnDisplayName: 'Status',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'noMissionStatusId',
      columnDisplayName: 'Exclude Status',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'myMissions',
      columnDisplayName: 'Only My Missions',
      type: FilterType.Boolean,
      existingValues: [],
      options: [],
    },
    {
      column: 'priorityId',
      columnDisplayName: 'SLA Profile',
      type: FilterType.Dropdown,
      existingValues: [],
      options: [],
    },
    {
      column: 'missionName',
      columnDisplayName: 'Mission Name',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'customerName',
      columnDisplayName: 'Customer',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'projectName',
      columnDisplayName: 'Project',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'portfolioName',
      columnDisplayName: 'Portfolio',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'locationName',
      columnDisplayName: 'Location',
      type: FilterType.Text,
      existingValues: [],
    },
    {
      column: 'captureDate',
      columnDisplayName: 'Capture Date',
      type: FilterType.DateWithOptions,
      existingValues: [],
      options: [],
    },
    {
      column: 'pilotName',
      columnDisplayName: 'Pilot',
      type: FilterType.Text,
      existingValues: [],
    },
    // {
    //   column: 'assignedToName',
    //   columnDisplayName: 'Assigned To',
    //   type: FilterType.Text,
    //   existingValues: [],
    // },
    {
      column: 'jobId',
      columnDisplayName: 'Job Id',
      type: FilterType.Numeric,
      existingValues: [],
    },
    {
      column: 'creationDate',
      columnDisplayName: 'Creation Date',
      type: FilterType.DateWithOptions,
      existingValues: [],
      options: [],
    },
    {
      column: 'deliverableDueDate',
      columnDisplayName: 'Deliverable Due Date',
      type: FilterType.DateWithOptions,
      existingValues: [],
      options: [],
    },
    {
      column: 'manualAirspaceWaiverRequired',
      columnDisplayName: 'Airspace Waiver Required',
      type: FilterType.Boolean,
      existingValues: [],
      newValue: 'False',
      options: [],
    },
    {
      column: 'userResponsible',
      columnDisplayName: 'Current Assignee',
      type: FilterType.UserSelection,
      existingValues: [],
      options: [],
    },
  ],
};
