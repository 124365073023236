import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ToasterService } from '@abp/ng.theme.shared';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { CaptureWithDeliverablesDto, Pilot } from '../../models/capture-with-deliverables-dto';
import {
  ActionOrderConfiguredDto,
  CustomerActionOrderConfiguredDto,
  GetStatusesInput,
  StatusDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { ConfigStateService } from '@abp/ng.core';
import {
  ActionOrderService,
  StatusService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';
import { PilotSourcingCommunicationService } from '../../pilot-sourcing-communication-service';
import { format } from 'date-fns';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics/pilot.service';
import { PilotDto } from 'projects/pilots-service/src/lib/proxy/pilots-service/basics';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { MatDialog } from '@angular/material/dialog';
import { ChatPilotModalComponent } from '../chat-pilot-modal/chat-pilot-modal.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionOrdered } from '../../models/action-ordered-dto';
import { MissionFlowDto } from '../../models/mission-flow-dto';
import { CaptureMustBeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';
import { CapturesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { UpdateCaptureDescriptionDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { PilotInfoComponent } from '../pilot-info/pilot-info.component';
import { removeHtmlText } from '../../../utils/control-utils';
import { CustomerActionOrderService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups/customer-action-order.service';

@Component({
  selector: 'app-captures',
  templateUrl: './captures.component.html',
  styleUrls: ['./captures.component.scss'],
})
export class CapturesComponent implements OnInit, OnDestroy {
  @Output() captureIdClicked = new EventEmitter<CaptureWithDeliverablesDto>();
  @Input() missionId: string = '';
  @Input() missionName: string = '';
  @Input() missionStatusId: string = '';
  @Input() mission: MissionFlowDto;
  @Input() reloadActions: Subject<any>;
  @Input() customerId: string;

  captures: CaptureWithDeliverablesDto[] = [];

  captureColumns = [
    { name: 'Capture #', prop: 'captureNumber' },
    { name: 'Capture Date', prop: 'captureDate' },
    { name: 'Capture Time', prop: 'captureTime' },
    { name: 'Capture Must be', prop: 'captureMustBe' },
    { name: 'Pilot Pay', prop: 'pilotPay' },
    { name: 'Pilot Payment Date', prop: 'pilotPaymentDate' },
    { name: 'Pilots', prop: 'pilots' },
    { name: 'Flexible Capture Time', prop: 'flexibleCaptureTime' },
    { name: 'Deliverables Included', prop: 'deliverablesIncluded' },
    { name: 'Actions' },
  ];

  // Actions visibility variables
  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentUserRoles: Array<string>;
  public readonly NAVIGATE_TO_PILOT_SOURCING = 'navigate_to_pilot_sourcing';
  allowNavigateToPilotSourcing: boolean = false;
  // Actions visibility variables

  subscription = new Subscription();

  pilotSourcingCommunicationSubscription: Subscription;

  public readonly CHAT_WITH_PILOT = 'chat_with_pilot';
  allowChatWithPilot: boolean = false;

  pilots: Pilot[];
  selectedPilotId: string;
  formSelectPilot: FormGroup;
  actions: ActionOrdered[];
  currentStatuses: Array<StatusDto>;

  @ViewChild('selectPilotModal') selectPilotModal: TemplateRef<any>;
  @ViewChild('showPilotsModal') showPilotsModal: TemplateRef<any>;

  private readonly ALLOWED_ORDER_ACTIONS = [
    'add_pilot_payment',
    'pilot-payment-date',
    'change_capture_data',
  ];

  public readonly EDIT_CAPTURE_DESCRIPTION = 'edit_capture_description';
  allowEditCaptureDescription: boolean = false;
  @ViewChild('editCaptureDescriptionModal') editCaptureDescriptionModal: TemplateRef<any>;

  formEditCaptureDescription: FormGroup;

  customerActionOrders: Array<CustomerActionOrderConfiguredDto>;

  constructor(
    private toaster: ToasterService,
    private missionFlowService: MissionFlowService,
    private stateService: ConfigStateService,
    private readonly actionOrderService: ActionOrderService,
    private notificationBroadcastService: NotificationBroadcastService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private pilotService: PilotService,
    private usersService: IdentityUserService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private readonly statusService: StatusService,
    private readonly captureService: CapturesService,
    private customerActionOrderService: CustomerActionOrderService,
  ) {}

  ngOnInit() {
    this.getMissionStatuses();
    this.loadCaptureData();
    this.loadActionInformation();

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (
          notif.notificationKey ==
          enumWebBackgroundNotificationKey.EventForMissionDetailDeliverableGrid
        ) {
          if (notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForTable) {
            if (
              notif.itemId &&
              notif.itemId.toLocaleLowerCase() == this.missionId.toLocaleLowerCase() &&
              notif.extraArgument.deliverableInfo
            ) {
              this.loadCaptureData();
            }

            if (
              notif.itemId &&
              notif.itemId.toLocaleLowerCase() == this.missionId.toLocaleLowerCase() &&
              notif.extraArgument.captureUpdated
            ) {
              let captureUpdated = this.captures.find(
                x =>
                  x.captureId.toLocaleLowerCase() ==
                  notif.extraArgument.captureUpdated.toLocaleLowerCase(),
              );

              if (captureUpdated) {
                captureUpdated.fixedCaptureDate = notif.extraArgument.fixedCaptureDate;
                captureUpdated.fixedCaptureTime = notif.extraArgument.fixedCaptureTime;
              }
            }
          }
        } else if (
          notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus
        ) {
          if (
            notif.itemId &&
            (notif.extraArgument.missionStatus || notif.extraArgument.missionStatus == 0) &&
            notif.extraArgument.missionStatusCode &&
            this.missionId == notif.itemId
          ) {
            let statusUpdate = this.currentStatuses.find(
              s => s.code.toLowerCase() == notif.extraArgument.missionStatusCode.toLowerCase(),
            );

            this.missionStatusId = statusUpdate.id;
            this.mission.missionStatusCode = notif.extraArgument.missionStatusCode;

            this.loadCaptureData();
            this.loadActionInformation();
            this.configureActionsVisibility();
          }
        }
      }),
    );

    this.pilotSourcingCommunicationSubscription =
      this.pilotSourcingCommunicationService.reloadFunctionCalled$.subscribe(() => {
        this.loadCaptureData();
      });

    this.getActionConfiguration();

    this.reloadActions.subscribe(v => {
      this.getActionConfiguration();
    });
  }

  private getMissionStatuses(): void {
    this.statusService.getList(<GetStatusesInput>{}).subscribe(res => {
      this.currentStatuses = res.items;
    });
  }

  getActionData(row: CaptureWithDeliverablesDto) {
    const uniqueDeliverables = [...new Set(row.deliverables.map(deliverable => deliverable.name))];
    const payDateConverted = row.pilotPayDate
      ? format(new Date(row.pilotPayDate + 'Z'), 'MM/dd/yyyy')
      : '';
    let enumMustValue: string = '';

    if (row.captureMustBe) {
      enumMustValue = CaptureMustBeEnum[row.captureMustBe.replace(/\s/g, '')]?.toString();
    }

    const dateTime =
      row.fixedCaptureDate && row.fixedCaptureTime
        ? `${row.fixedCaptureDate} ${row.fixedCaptureTime}`
        : `${row.captureDate} ${row.captureDateTime ?? '00:00'}` || '-';

    return {
      captureDate: row.fixedCaptureDate || row.captureDate || '-',
      captureTime: row.fixedCaptureTime || row.captureTime || '-',
      captureDateTime: dateTime,
      captureNumber: row.captureNumber,
      deliverables: uniqueDeliverables.join(', '),
      pilotPayDef: row.pilotPay || 0,
      pilotDateDef: row.pilotPayDate || payDateConverted || '',
      captureId: row.captureId,
      captureMustBeDef: enumMustValue,
      missionName: this.missionName,
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.pilotSourcingCommunicationSubscription.unsubscribe();
    this.reloadActions.unsubscribe();
  }

  navigateToSourcing(row: CaptureWithDeliverablesDto) {
    this.captureIdClicked.emit(row);
  }

  formatTime(time: string): string {
    if (!time) return '-';

    const [hours, minutes] = time.split(':');
    const formattedHours = parseInt(hours, 10) > 12 ? parseInt(hours, 10) - 12 : hours;
    const amOrPm = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${amOrPm}`;
  }

  private loadCaptureData(): void {
    this.missionFlowService.getCapturesWithDeliverables(this.missionId).subscribe({
      next: response => {
        response = response.map(r => {
          const timeRegex = /(\d+):(\d+)/;
          let time = null;
          const match = String(r.captureTime).match(timeRegex);

          if (match) {
            time = match[0] + ':00';
          }

          r.deliverablesAsString = r.deliverables.map(d => d.name)?.join(', ') || '--';

          r.captureDateTime = time
            ? new Date(new Date(`${r.captureDate}T${time}`).toLocalISOString())
            : new Date(r.captureDate);
          r.missionDescription =
            'In the realm of Eldoria, a forgotten prophecy awakens, binding the fates of disparate races as they unite against an ancient darkness threatening to devour their world.';
          return r;
        });

        this.captures = response;
        // This will be re-added after
        // if (this.captures.length === 1) {
        //   const [capture] = this.captures;
        //   this.navigateToSourcing(capture);
        //   this.toaster.info('Only one capture available. Redirecting to Pilot Sourcing.');
        // }
      },
      error: err => console.log(err),
    });
  }

  private loadActionInformation(): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');

    combineLatest([
      this.missionFlowService.getActionsPermissions(this.mission.missionStatusCode),
      this.customerActionOrderService.filterCustomerOrdersByRoles(this.currentUserRoles),
    ]).subscribe({
      next: ([standardActions, customerActionOrders]) => {
        this.customerActionOrders = customerActionOrders;

        let actionsOrder = standardActions
          .filter(r => this.ALLOWED_ORDER_ACTIONS.includes(r.code))
          .sort((a, b) => a.order - b.order);

        actionsOrder = this.addRemoveCustomerActions(this.ALLOWED_ORDER_ACTIONS, actionsOrder);

        const unique = (actionsOrder, track = new Set()) =>
          actionsOrder.filter(({ description }) =>
            track.has(description) ? false : track.add(description),
          );

        this.actions = unique(actionsOrder);
      },
      error: err => console.error('Error getting actions', err),
    });
  }

  private addRemoveCustomerActions(
    allowedActions: string[],
    currentActions: ActionOrdered[],
  ): ActionOrdered[] {
    let hiddenForCustomer = this.customerActionOrders.filter(
      t =>
        allowedActions.includes(t.code) &&
        t.statusId == this.missionStatusId &&
        t.customerId == this.customerId &&
        t.order < 0,
    );

    if (hiddenForCustomer.length > 0) {
      let codesToRemove = hiddenForCustomer.map(x => x.code);

      currentActions = currentActions.filter(x => !codesToRemove.includes(x.code));
    }

    let addForCustomer = this.customerActionOrders.filter(
      t =>
        allowedActions.includes(t.code) &&
        t.statusId == this.missionStatusId &&
        t.customerId == this.customerId &&
        t.order > 0,
    );

    if (addForCustomer.length > 0) {
      for (let item of addForCustomer) {
        currentActions.push(<ActionOrdered>item);
      }
    }

    currentActions.sort((a, b) => a.order - b.order);

    return currentActions;
  }

  private getActionConfiguration(): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    this.actionOrderService.filterByRoles(this.currentUserRoles).subscribe(res => {
      this.currentActionConfiguration = res;
      this.configureActionsVisibility();
    });
  }

  private configureActionsVisibility(): void {
    if (!this.missionStatusId) return;

    this.allowNavigateToPilotSourcing =
      (this.currentActionConfiguration.find(
        t => t.code == this.NAVIGATE_TO_PILOT_SOURCING && t.statusId == this.missionStatusId,
      ) &&
        !this.shouldHideByCustomer(this.NAVIGATE_TO_PILOT_SOURCING)) ||
      this.shouldAddByCustomer(this.NAVIGATE_TO_PILOT_SOURCING);

    this.allowChatWithPilot =
      (this.currentActionConfiguration.find(
        t => t.code == this.CHAT_WITH_PILOT && t.statusId == this.missionStatusId,
      ) &&
        !this.shouldHideByCustomer(this.CHAT_WITH_PILOT)) ||
      this.shouldAddByCustomer(this.CHAT_WITH_PILOT);

    this.allowEditCaptureDescription =
      (this.currentActionConfiguration.find(
        t => t.code == this.EDIT_CAPTURE_DESCRIPTION && t.statusId == this.missionStatusId,
      ) &&
        !this.shouldHideByCustomer(this.EDIT_CAPTURE_DESCRIPTION)) ||
      this.shouldAddByCustomer(this.EDIT_CAPTURE_DESCRIPTION);
  }

  private shouldHideByCustomer(actionCode: string): boolean {
    let hiddenAction = this.customerActionOrders.find(
      t =>
        t.code == actionCode &&
        t.statusId == this.missionStatusId &&
        t.customerId == this.customerId &&
        t.order < 0,
    );

    return !!hiddenAction;
  }

  private shouldAddByCustomer(actionCode: string): boolean {
    let allowedAction = this.customerActionOrders.find(
      t =>
        t.code == actionCode &&
        t.statusId == this.missionStatusId &&
        t.customerId == this.customerId &&
        t.order > 0,
    );

    return !!allowedAction;
  }

  reloadPilotSourcing() {
    this.pilotSourcingCommunicationService.callReloadFunction({ reloadComplementarioInfo: true });
  }

  showSelectPilotDialog(row: CaptureWithDeliverablesDto): void {
    if (!row.pilots || row.pilots?.length < 1) {
      return;
    }
    this.pilots = row.pilots;
    this.formSelectPilot = this.fb.group({
      pilotId: [this.selectedPilotId ?? null, [Validators.required]],
    });
    this.dialog.open(this.selectPilotModal, { width: '600px', disableClose: true });
  }

  chatWithPilot(): void {
    let formValue = this.formSelectPilot.getRawValue();
    this.selectedPilotId = formValue.pilotId;
    this.pilotService
      .get(this.selectedPilotId)
      .subscribe(pilotResponse => this.openDialog(pilotResponse));
  }

  openDialog(pilot: PilotDto): void {
    this.usersService.get(pilot.userId).subscribe(user => {
      this.closeModal();
      const dialogRef = this.dialog.open(ChatPilotModalComponent, {
        width: '825px',
        disableClose: true,
        data: {
          userId: user.id,
          name: user.name,
          surname: user.surname,
          username: user.userName,
        },
      });
    });
  }

  closeModal(): void {
    this.dialog.closeAll();
  }

  showChat(row: CaptureWithDeliverablesDto): boolean {
    return this.allowChatWithPilot && row && row.pilots?.length > 0;
  }

  showEditCaptureDialog(row: CaptureWithDeliverablesDto): void {
    this.pilots = row.pilots;
    this.formEditCaptureDescription = this.fb.group({
      captureDescription: [row.scopeOfWork, [Validators.required]],
      captureId: [row.captureId],
    });

    this.dialog.open(this.editCaptureDescriptionModal, { width: '600px', disableClose: true });
  }

  sanitizeHtml(text: string): string {
    return removeHtmlText(text);
  }

  editCaptureDescription(): void {
    const formValue = this.formEditCaptureDescription.getRawValue();
    const updateCapture: UpdateCaptureDescriptionDto = {
      captureId: formValue.captureId,
      description: formValue.captureDescription,
    };

    this.captureService.updateCaptureDescription(updateCapture).subscribe({
      next: response => {
        let capture = this.captures.find(x => x.captureId === response.id);
        capture.scopeOfWork = response.scopeOfWork || formValue.captureDescription;
        this.closeModal();
      },
      error: err => console.log(err),
    });
  }

  displayPilotsModal(pilots: PilotDto[]) {
    this.dialog.open(this.showPilotsModal, {
      data: pilots,
      width: '250px',
      panelClass: 'pilots-list',
    });
  }

  /**
   * Shows an embeded version of the pilot profile through a Material Dialog
   * @param id string
   * @returns void
   */
  displayPilotProfile(id: string): void {
    this.dialog.open(PilotInfoComponent, {
      data: { id },
      width: '90%',
      height: '90%',
      disableClose: true,
      position: { top: '50px' },
    });
  }
}
