import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Observable,
  map,
  catchError,
  of,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  finalize,
  combineLatest,
  forkJoin,
} from 'rxjs';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { PagedResultDto } from '@abp/ng.core';

import {
  CustomersDto,
  enumState,
  GetCustomerInput,
} from 'projects/customers-service/src/lib/proxy/customers-service/basics';
import {
  GetIdentityUsersInput,
  IdentityUserDto,
  IdentityUserService,
} from '@volo/abp.ng.identity/proxy';

export type UserData = { id: String; userName: String; roleNames: Array<string> };

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
})
export class UserAutocompleteComponent implements OnInit {
  @Input() preloadedUser?: IdentityUserDto;
  @Input() required?: boolean = false;
  @Output() userSelected: EventEmitter<UserData | null>;
  filteredOptions: Observable<IdentityUserDto[]>;
  search: FormGroup;
  loading: boolean = false;

  constructor(private identityService: IdentityUserService) {
    this.userSelected = new EventEmitter();
  }

  ngOnInit(): void {
    this.search = new FormGroup({
      term: new FormControl<string>(this.preloadedUser?.name || ''),
    });

    if (this.required) {
      this.search.get('term').addValidators(Validators.required);
    }

    this.filteredOptions = this.search?.get('term').valueChanges.pipe(
      debounceTime(333),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term?.length) {
          return this.searchCustomer(term.toLowerCase());
        } else if (term?.length === 0) {
          this.emitUser(null);
        }

        return of([]);
      }),
    );
  }

  /**
   * Handles customer selection on the autocomplete
   * @param event MatAutocompleteSelectedEvent
   */
  handleUserSelection(event: MatAutocompleteSelectedEvent) {
    const user: IdentityUserDto = event.option.value;

    this.emitUser({ id: user.id, userName: user.name, roleNames: user.roleNames });
  }

  /**
   * Formats the name of the customer to display
   * @param user any
   * @returns string
   */
  formatUser(user: any): string {
    return user?.name || user;
  }

  /**
   * Clears the search query
   */
  clear() {
    this.search.get('term').setValue('', { emitEvent: false });
  }

  /**
   * Emits the passed value to consumers
   * @param data CustomerData | null
   */
  private emitUser(data: UserData | null) {
    this.userSelected.emit(data);
  }

  /**
   * Searches for customers by filtering using the name
   * @param name string
   * @returns Observable<CustomersDto[]>
   */
  private searchCustomer(name: string): Observable<IdentityUserDto[]> {

    let requests = this.splitSearchCriteria(name);

    let users: IdentityUserDto[] = [];

    this.loading = true;

    return forkJoin(requests)
    .pipe(
      map((results: Array<PagedResultDto<IdentityUserDto>>) => {
          
        for(let res of results) {
  
          let partialResults = res.items.map(x => {
            x['originName'] = x.name;
            return { ...x, name: x.userName };
          });
  
          users = users.concat(partialResults);
        }
  
        const uniqueItems = users.filter((item, index, self) =>
          index === self.findIndex(t => t.name === item.name)
        );
  
        users = uniqueItems;
        
        if (users.length > 0) {
          this.search.get('term').setErrors(null);
        } else {
          this.search.get('term').setErrors({ notFound: true });
        }

        return users;
      }),
      catchError(e => {
        console.error('Error while fetching users', e);
        return of([]);
      }),
      finalize(() => (this.loading = false)),
    );
  }


  private splitSearchCriteria(searchText: string): Array<any> {

    let splitCriteria = searchText.split(' ');

    if (splitCriteria.length == 1) {

      const singleCriteria: GetIdentityUsersInput = {
        filter: searchText,
        skipCount: 0,
        maxResultCount: 100,
        isExternal: false,
      };
  

      return [this.identityService.getList(singleCriteria)]
    
    } else {

      const completeCriteria: GetIdentityUsersInput = {
        filter: searchText,
        skipCount: 0,
        maxResultCount: 100,
        isExternal: false,
      };

      let result = [this.identityService.getList(completeCriteria)];

      for(let i = 0; i < splitCriteria.length; i++) {

        if (splitCriteria[i].length < 3)
          continue;

        let newCriteria: GetIdentityUsersInput = {
          filter: splitCriteria[i],
          skipCount: 0,
          maxResultCount: 100,
          isExternal: false,
        };

        result.push(this.identityService.getList(newCriteria))
      }

      return result;
    }

    return [];
  }
}
