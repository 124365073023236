import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { CreateFolderModalService } from './create-folder-modal.service';
import { BaseModalComponent } from '../base-modal.component';
import { UpdateStreamService } from '../../services';
import { debounceTime, distinctUntilChanged, first, map, Observable, switchMap } from 'rxjs';
import { CreateDirectoryInput } from '@volo/abp.ng.file-management/proxy';

@Component({
  selector: 'abp-create-folder-modal',
  templateUrl: './create-folder-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CreateFolderModalService],
})
export class CreateFolderModalComponent extends BaseModalComponent {
  form = this.fb.group({
    name: ['', [Validators.required], [this.folderNameValidator()], { updateOn: 'blur' }],
  });

  @Input() parentId: string;
  @Input() rootId: string;
  @Input() missionId?: string;
  @Input() deliverableId?: string;
  @Input() captureId?: string;
  @Input() orderDetailId?: string;

  constructor(
    private fb: UntypedFormBuilder,
    private service: CreateFolderModalService,
    private updateStream: UpdateStreamService,
  ) {
    super();
  }

  shouldSave() {
    return this.form.valid;
  }

  saveAction() {
    if (
      (!this.updateStream.currentDirectory || this.updateStream.currentDirectory == 'ROOT_ID') &&
      this.rootId
    )
      return this.service.create(
        this.form.controls.name.value,
        this.parentId,
        this.rootId,
        this.missionId,
        this.deliverableId,
        this.captureId,
        this.orderDetailId,
      );

    return this.service.create(
      this.form.controls.name.value,
      this.parentId,
      undefined,
      this.missionId,
      this.deliverableId,
      this.captureId,
      this.orderDetailId,
    );
  }

  clear() {
    this.form.reset();
  }

  folderNameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(folderName => {
          return this.service
            .checkFolderName({ name: folderName } as CreateDirectoryInput)
            .pipe(map(isValid => (!isValid ? { isFolderNameNotValid: true } : null)));
        }),
        first(),
      );
    };
  }
}
