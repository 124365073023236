import { eLayoutType, RoutesService } from '@abp/ng.core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';
import { APP_INITIALIZER } from '@angular/core';
import { EnvironmentService } from '@abp/ng.core';

export const APP_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/notifications',
        name: 'Notification',
        iconClass: 'fas fa-regular fa-envelope-open',
        order: 3,
        layout: eLayoutType.application,
        requiredPolicy: 'WebNotifications.ShowNotification.ShowMenu',
      },
      {
        path: '/chat',
        name: 'Chat',
        iconClass: 'fas fa-comment-alt',
        order: 2,
        layout: eLayoutType.application,
        requiredPolicy: 'Chat.Messaging',
      },
      {
        path: '/pilot-sourcing',
        name: 'My Orders',
        order: 1,
        iconClass: 'fa fa-th',
        layout: eLayoutType.application,
      },
      {
        path: '/orders/list',
        name: 'Orders',
        parentName: 'My Orders',
        requiredPolicy: 'MyOrders.Orders.ShowMenu',
      },
      {
        path: '/orders/bulk-import-requests',
        name: 'Bulk Import Requests',
        parentName: 'My Orders',
        requiredPolicy: 'MyOrders.BulkImport.ShowMenu',
      },
      {
        path: '/pilot-sourcing/pilot-finder',
        name: 'Pilot Finder',
        parentName: 'My Orders',
        requiredPolicy: 'MyOrders.PilotFinder.ShowMenu',
      },
      {
        path: '/pilot-sourcing/pilot-workbench',
        name: 'Pilot Workbench',
        parentName: 'My Orders',
        requiredPolicy: 'MyOrders.PilotWorkbench.ShowMenu',
      },
      {
        path: '/accounting',
        name: 'Accounting',
        order: 1,
        iconClass: 'fa fa-receipt',
        layout: eLayoutType.application,
      },
      {
        path: '/missions-service/accounting/pilot-payment',
        name: 'Pilot Payment',
        parentName: 'Accounting',
        requiredPolicy: 'missionsService.Accounting.ShowMenu',
      },
      // Comment for Demo purposes
      // {
      //   path: '/pilot-sourcing/reports',
      //   name: 'Reports',
      //   parentName: 'Pilot Success',
      //   order: 3,
      // },
      {
        path: '/elsa',
        name: 'Business Workflow',
        iconClass: 'fa fa-stream',
        order: 5,
        layout: eLayoutType.application,
        requiredPolicy: 'ElsaService.ElsaDashboard',
      },
      //TODO: check ABP routing because if we not use providers, the navigation fails. Ticket: https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/10051
      {
        path: '/500',
        name: 'Internal Server Error',
        iconClass: 'fa fa-stream',
        order: 10,
        layout: eLayoutType.application,
        requiredPolicy: 'CustomHack',
      },
      {
        path: '/forbidden',
        name: 'Forbidden',
        iconClass: 'fa fa-stream',
        order: 11,
        layout: eLayoutType.application,
        requiredPolicy: 'CustomHack',
      },
      {
        path: '/no-internet',
        name: 'NoInternet',
        iconClass: 'fa fa-stream',
        order: 12,
        layout: eLayoutType.application,
        requiredPolicy: 'CustomHack',
      },
      //{
      //  path: '/financials',
      //  name: 'Financials Demo',
      //   iconClass: 'fa fa-address-book',
      //  order: 2,
      //  layout: eLayoutType.application,
      //},
      {
        path: '/sandbox',
        name: 'Sandbox',
        iconClass: 'fa fa-cubes',
        order: 999,
        layout: eLayoutType.application,
        invisible: true,
      },
      {
        path: '/health',
        name: 'Health',
        iconClass: 'fa fa-heart',
        order: 999,
        layout: eLayoutType.application,
        requiredPolicy: 'General.ShowMenu.Administration',
      },
      {
        path: '/webhooks',
        name: 'Webhooks',
        iconClass: 'fa fa-screwdriver',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: 'CoreService.WebhookSubscriptions',
      },
      {
        path: '/rule-designer',
        name: 'Rule Designer',
        iconClass: 'fa fa-ruler',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: 'missionsService.Rules',
      },
      {
        path: '/access-denied',
        name: 'Access Denied',
        iconClass: 'fa fa-stream',
        order: 12,
        layout: eLayoutType.application,
        requiredPolicy: 'CustomHack',
      },
    ]);

    routes.patch(eThemeSharedRouteNames.Administration, {
      requiredPolicy: 'General.ShowMenu.Administration',
    });
  };
}
