<ngx-spinner bdColor="rgba(245,245,245,0.7)"
  size="default"
  color="#bc5a2e"
  type="ball-square-clockwise-spin"
  [fullScreen]="true"
  [zIndex]="1111">
  <p style="color: #bc5a2e">{{ messageOnRemoving }}</p>
</ngx-spinner>
<ng-container *ngIf="content$ | async; let data">
  <div class="actions-container col-12">
    <section class="mt-1">
      <mat-checkbox class="example-margin"
        *ngIf="currentList?.length > 0"
        [(ngModel)]="selectAll"
        (change)="onSelectAll($event, this.currentList)">
        Select all
      </mat-checkbox>
    </section>
    <button type="button"
      class="btn"
      *ngIf="filesSelected?.length > 0 && !isSharedLink"
      title="{{ 'FileManagement::DeleteSelectionTooltip' | abpLocalization }}"
      (click)="handleOnRemoveSelection()">
      <mat-icon>
        <span class="material-symbols-outlined"> delete_sweep </span>
      </mat-icon>
    </button>
    <button type="button"
      class="btn"
      (click)="this.switchActiveTab(this.TAB_MAIN_FILES_MANAGER)"
      *ngIf="!missionFilter"
      [ngClass]="{ 'pull-right': !missionFilter }">
      <mat-icon [class.mat-primary]="activeTab === this.TAB_MAIN_FILES_MANAGER">format_list_bulleted</mat-icon>
    </button>
    <button type="button"
      class="btn"
      (click)="this.switchActiveTab(this.TAB_DIRECTORY_CONTENT)"
      [ngClass]="{ 'pull-right': missionFilter }">
      <mat-icon [class.mat-primary]="activeTab === this.TAB_DIRECTORY_CONTENT">grid_view</mat-icon>
    </button>
    <button type="button"
      class="btn"
      (click)="this.switchActiveTab(this.TAB_CONTENT_PREVIEW)">
      <mat-icon [class.mat-primary]="activeTab === this.TAB_CONTENT_PREVIEW">subscriptions</mat-icon>
    </button>
  </div>
  <div class="list-view"
    *ngIf="activeTab == this.TAB_MAIN_FILES_MANAGER">
    <abp-extensible-table [list]="list"
      [data]="data.items"
      [recordsTotal]="data.totalCount"
      *ngIf="activeTab == this.TAB_MAIN_FILES_MANAGER && !missionFilter">
    </abp-extensible-table>
  </div>

  <div class="row tile-view"
    *ngIf="activeTab == this.TAB_DIRECTORY_CONTENT"
    #container>
    <div class="d-flex align-items-center justify-content-center mt-4 text-muted"
      *ngIf="currentList.length == 0">
      <h2>This folder is empty</h2>
    </div>
    <mat-card class="mat-mdc-card mdc-card mb-4 col-sm-3 col-md-3 d-flex card-tile-view card-item pb-2"
      *ngFor="let item of currentList; let i = index">
      <mat-card-content class="mat-mdc-card-content">
        <div class="row text-center">
          <div class="actions-container">
            <mat-checkbox [checked]="isCheckBoxSelected(item)"
              (change)="onCheckboxChange($event, item)"
              [aria-label]="selectDirectoryContent"></mat-checkbox>
            <button class="fileInProcess"
              matTooltip="The file is being processed. Click to update."
              *ngIf="
                !item.processingFinished &&
                !item.isDirectory &&
                item.imagePreviewUrl == null &&
                item.fileContentPreviewUrl == null &&
                item.thumbnailUrl == null &&
                item.videoPreviewUrl == null
              "
              (click)="updateProcessingStatus()">
              <span class="material-symbols-outlined"> deblur </span>
            </button>
            <button mat-icon-button
              class="pull-right pt-7"
              *ngIf="item.validationResults && !isSharedLink"
              color="warn"
              (click)="openValidationResultsDialog(item)">
              <mat-icon>warning</mat-icon>
            </button>
            <button mat-icon-button
              [matMenuTriggerFor]="menu"
              class="pull-right pt-7"
              *ngIf="showOptions && (!isSharedLink || (isSharedLink && !item.isDirectory))">
              <mat-icon>more_vert</mat-icon>
            </button>
            <div class="mt-4"
              *ngIf="!displayIconAction(item) && showOptions">
              <br />
            </div>
            <mat-menu #menu="matMenu">
              <ng-container *ngIf="!item.isDirectory">
                <button mat-menu-item
                  (click)="downloadFile(item)">
                  <span>Download</span>
                </button>
                <button mat-menu-item
                  (click)="renameFile(item)"
                  *ngIf="!isSharedLink">
                  <span>Rename</span>
                </button>
                <button mat-menu-item
                  (click)="moveFile(item)"
                  *ngIf="optionsFilter?.move && !isSharedLink">
                  <span>Move</span>
                </button>
                <button mat-menu-item
                  (click)="deleteFile(item)"
                  *ngIf="!isSharedLink">
                  <span>Delete</span>
                </button>
              </ng-container>

              <ng-container *ngIf="item.isDirectory && !isSharedLink">
                <button mat-menu-item
                  (click)="renameFolder(item)"
                  *ngIf="canRenameFolder(item) && !isSharedLink">
                  <span>Rename</span>
                </button>
                <button mat-menu-item
                  (click)="moveFolder(item)"
                  *ngIf="canMoveFolder(item) && optionsFilter?.move && !isSharedLink">
                  <span>Move</span>
                </button>
                <button mat-menu-item
                  (click)="deleteFolder(item)"
                  *ngIf="canDeleteFolder(item) && !isSharedLink">
                  <span>Delete</span>
                </button>
              </ng-container>
            </mat-menu>
          </div>
          <div *ngIf="item.isDirectory"
            (click)="openFolder(item)"
            class="folder">
            <img src="assets/images/logo/folder-logo.png"
              class="col-sm-4" />
            <br /><br />
            <span class="mt-15 d-inline-block">{{ item.name }}</span>
          </div>
          <div *ngIf="!item.isDirectory">
            <span class="col-12 fa-file far manager-folder-icon"></span>
            <br /><br />
            <span class="mt-15 d-inline-block">{{ item.name }}</span>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer class="mt-3">
        <div class="row">
          <div class="col-3 text-start">
            <span *ngIf="item.isDirectory">
              {{ item.itemsCount }}
              <span *ngIf="item.itemsCount > 1">
                {{ 'FileManagement::Items' | abpLocalization }}
              </span>
              <span *ngIf="item.itemsCount <= 1">
                {{ 'FileManagement::Item' | abpLocalization }}
              </span>
            </span>
          </div>
          <div class="col-4 text-end">{{ getItemSize(item.size) }}</div>
          <div class="col-1 text-end"><span class="vr"></span></div>
          <div class="col-3 text-end">
            <span>{{ i + 1 }}<span class="font-weight-bold">/</span>{{ totalItems }}</span><span></span>
          </div>
        </div>
      </mat-card-footer>
    </mat-card>
  </div>

  <div class="row media-preview"
    *ngIf="activeTab == this.TAB_CONTENT_PREVIEW"
    #mediaPreviewContainer>
    <div class="d-flex align-items-center justify-content-center mt-4 text-muted"
      *ngIf="filePreviewItems.length == 0">
      <h2>Only Available for Media Files</h2>
    </div>
    <mat-card class="mat-mdc-card mdc-card mb-5 tagus-card col-sm-3 col-md-3 d-flex card-item"
      *ngFor="let item of filePreviewItems; let i = index">
      <mat-card-content class="mat-mdc-card-content">
        <div class="row text-center">
          <div class="actions-container"
            *ngIf="showOptions">
            <mat-checkbox [checked]="isMediaPreviewCheckBoxSelected(item)"
              (change)="onPreviewCheckboxChange($event, item)"
              [aria-label]="selectDirectoryContent"></mat-checkbox>
            <button mat-icon-button
              class="pull-right pt-7"
              *ngIf="item.validationResults"
              color="warn"
              (click)="openValidationResultsDialog(item)">
              <mat-icon>warning</mat-icon>
            </button>
            <button mat-icon-button
              [matMenuTriggerFor]="menu"
              class="pull-right pt-7">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item
                (click)="renameFile(item.directoryContent)">
                <span>Rename</span>
              </button>
              <button mat-menu-item
                (click)="moveFile(item.directoryContent)">
                <span>Move</span>
              </button>
              <button mat-menu-item
                (click)="deleteFile(item.directoryContent)">
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
          <div>
            <image-viewer *ngIf="item.HasPreviewURL()"
              [id]="'img' + i"
              [allowReproduce]="!!item.videoUrl"
              [imageUrl]="item.GetMediaThumbnail()"
              [isThumbnail]="!!item.thumbnailUrl"
              [pictureTitle]="item.fileName"
              [allowClick]="item.IsPreviewReady()"
              (onPictureClicked)="handleOnPictureClicked(item, 'img' + i)"
              customClass="mt-15">
            </image-viewer>
            <span class="badge text-soft-danger"
              *ngIf="item.videoInProgress">Encoding...</span>
            <br *ngIf="item.videoInProgress" /><br />
            <div class="d-flex align-items-center justify-content-center">
              <span class="mt-1 d-inline-block text-truncate"
                [title]="item.fileName">{{
                item.fileName
                }}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <image-preview-modal size="lg"
    hideSaveButton="true"
    [imageId]="previewId"
    [(visible)]="previewModalOpen"
    [imageUrl]="previewUrl"
    [fileName]="fileName">
  </image-preview-modal>

  <video-preview-modal size="lg"
    hideSaveButton="true"
    (onCloseModal)="handleOnCloseModalVideo()"
    (visibleChange)="handleOnVisibleChange($event)"
    [(visible)]="videoPreviewModalOpen"
    [fileName]="fileName"
    [videoUrl]="videoPreviewUrl">
  </video-preview-modal>

  <pdf-preview-modal size="xl"
    hideSaveButton="true"
    [fileId]="previewId"
    [(visible)]="pdfPreviewModalOpen"
    [fileUrl]="pdfPreviewUrl"
    [fileName]="fileName">
  </pdf-preview-modal>

  <abp-rename-modal [(visible)]="renameModalOpen"
    [(contentToRename)]="contentToRename"
    (contentSaved)="onContentSaved()">
  </abp-rename-modal>

  <abp-move-file-modal [(visible)]="moveModalOpen"
    (contentSaved)="onContentSaved()"
    [fileToMove]="fileToMove"
    [oldParentId]="parentOfFileToMove"
    [rootId]="missionFilter?.rootFolder?.id"></abp-move-file-modal>
</ng-container>