import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MissionFlowDto } from 'projects/flyguys/src/app/pilot-sourcing/models/mission-flow-dto';
import { OrderRequestModel } from 'projects/flyguys/src/app/pilot-sourcing/models/order-request-model';
import { MissionFlowService } from 'projects/flyguys/src/app/services/mission-flow.service';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { SharedLinkDto } from '../../proxy/customers-service/basics';
import { ProductsDeliverablesService } from 'projects/flyguys/src/app/services/products-deliverables.service';
import {
  DirectoryDescriptorDto,
  DirectoryDescriptorService,
  FileDescriptorService,
  FilterMission,
  ZipDownloadResult,
} from '@volo/abp.ng.file-management/proxy';
import { DeliverableDto } from 'projects/flyguys/src/app/models/products-deliverables/deliverable-dto';
import { forkJoin, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'lib-sharedmission',
  templateUrl: './sharedmission.component.html',
  styleUrls: ['./sharedmission.component.scss'],
})
export class SharedmissionComponent implements OnInit {
  id: string | null = null;
  orderRequest: OrderRequestModel;
  missionFlow: MissionFlowDto;
  deliverables: DeliverableDto[] = [];
  showFolder: boolean;
  folderMissions: FilterMission;
  selectedDeliverable: DeliverableDto;
  missionId: string;
  error: boolean;
  private readonly loadingDelay: number = 3000;

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomersService,
    private missionService: MissionFlowService,
    private productsDeliverablesService: ProductsDeliverablesService,
    private readonly directoryService: DirectoryDescriptorService,
    private cdRef: ChangeDetectorRef,
    private readonly fileDescriptorService: FileDescriptorService,
    private snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    try {
      this.customerService.getSharedLinkData(this.id).subscribe(r => {
        if (r != null) {
          this.getData(r);
        } else {
          this.error = true;
        }
      });
    } catch (e) {
      this.error = true;
    }
  }

  goBack() {
    this.showFolder = false;
  }

  viewFiles(viewFile: DeliverableDto) {
    this.folderMissions = undefined;
    this.cdRef.detectChanges();

    this.folderMissions = {
      captureId: viewFile.lastCaptureId,
      deliverableId: viewFile.deliverableId,
      missionId: this.missionId,
      orderDetailId: viewFile.orderDetailId,
    };
    this.selectedDeliverable = viewFile;

    this.cdRef.detectChanges();

    setTimeout(() => {
      this.showFolder = true;
    }, this.loadingDelay);
  }

  downloadAllFiles() {
    this.spinner.show();
    const root$: Observable<DirectoryDescriptorDto>[] = [];
    this.deliverables.forEach(deliverable => {
      const observable = this.directoryService.getRoot(
        this.missionId,
        deliverable.deliverableId,
        deliverable.lastCaptureId,
        deliverable.orderDetailId,
      );
      root$.push(observable);
    });

    forkJoin(root$).subscribe({
      next: responses => {
        const rootFoldersIds: string[] = [];
        responses.forEach(response => {
          if (response?.id) {
            rootFoldersIds.push(response.id);
          }
        });
        if (rootFoldersIds.length === 0) return;
        this.generateZipFile(rootFoldersIds.join(','));
      },
      error: error => {
        console.error('Error when getting all roots:\n', error);
        this.spinner.hide();
      },
    });
  }

  generateZipFile(filesIds: string, deliverable?: DeliverableDto) {
    this.fileDescriptorService.validateContent(filesIds).subscribe({
      next: (hasContent: boolean) => {
        if (!hasContent) {
          this.snackBar.open('Folder has no content', 'close', {
            duration: this.loadingDelay,
          });

          this.spinner.hide();
          return;
        }
        this.fileDescriptorService.getDownloadTokenZip().subscribe({
          next: (response: ZipDownloadResult) => {
            this.fileDescriptorService.downloadZipFileUrl(
              response.url,
              response.token,
              filesIds,
              this.missionId,
              deliverable,
            );

            this.spinner.hide();
          },
          error: error => {
            console.error('Unable to get token:\n', error);
            this.spinner.hide();
          },
        });
      },
      error: error => {
        console.error('Unable to validate content:\n', error);
        this.spinner.hide();
      },
    });
  }

  getData(linkData: SharedLinkDto) {
    this.missionId = linkData.missionId;
    this.missionService.getMissionData(linkData.missionId).subscribe(r => {
      this.missionFlow = r;

      if (this.missionFlow.statusEnum != 11 && this.missionFlow.statusEnum != 23) this.error = true;
    });

    this.missionService.getOrderRequestData(linkData.missionId).subscribe(r => {
      this.orderRequest = r;
    });

    this.productsDeliverablesService
      .getDeliverableTableByMission(linkData.missionId)
      .subscribe(r => {
        this.deliverables = r;
        this.deliverables.forEach((d: DeliverableDto) => {
          d.deliverableName;
          this.directoryService
            .getRootFolderDetailsCapWithOut(linkData.missionId, d.deliverableId, d.orderDetailId)
            .subscribe(res => {
              d.filesCount = res.itemsCount;
              d.fileSize = res.size;
            });
        });
      });
  }

  openFolder(a) {}

  public getItemSize(size: number): string {
    const _100MBInByte = 102400;
    const _1GBInByte = 1073741824;
    const _1TBInByte = 1099511627776;

    const toKB = 1024;
    const toMB = 1048576;

    const formatNumber = (num: number, fractionDigits: number): string =>
      num.toFixed(fractionDigits);

    if (size < _100MBInByte) return `${formatNumber(size / toKB, 1)} KB`;

    if (size < _1GBInByte) return `${formatNumber(size / toMB, 1)} MB`;

    if (size < _1TBInByte) return `${formatNumber(size / _1GBInByte, 1)} GB`;

    return `${formatNumber(size / _1TBInByte, 1)} TB`;
  }
}
