import { mapEnumToOptions } from '@abp/ng.core';

export enum enumNotificationCategoryType {
  QAQCAlerts,
  CustomerUpdates,
}

export const notificationCategoriesDisplayNames: Record<enumNotificationCategoryType, string> = {
  [enumNotificationCategoryType.QAQCAlerts]: 'QAQC Alerts',
  [enumNotificationCategoryType.CustomerUpdates]: 'Customer Updates',
};

export const EnumNotificationStatusOptions = mapEnumToOptions(enumNotificationCategoryType);

export namespace enumNotificationCategoryType {
  export function getOptions(): any {
    return Object.entries(notificationCategoriesDisplayNames).map(([id, description]) => ({
      id,
      description,
    }));
  }
}
