import { ConfigStateService, ListService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { EXTENSIONS_IDENTIFIER } from '@abp/ng.theme.shared/extensions';
import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnDestroy,
  SimpleChanges,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DeleteService,
  DirectoryTreeService,
  DownloadService,
  MoveService,
  NavigatorService,
  UpdateStreamService,
  UploadService,
  eFileManagementComponents,
} from '@volo/abp.ng.file-management';
import {
  DirectoryContentDto,
  DirectoryDescriptorDto,
  DirectoryDescriptorService,
  FileDescriptorService,
  FileSelected,
  FilterMission,
  ZipDownloadResult,
  DirectoryContentRequestInput,
  MoveDirectoryInput,
  MoveFileInput,
  SelectedAllDto,
} from '@volo/abp.ng.file-management/proxy';
import { firstValueFrom, lastValueFrom, Subject, Subscription, finalize, map } from 'rxjs';
import { FlyguysMapComponent, FlyguysMapContextMenuItem, FlyguysMapMarker } from '@flyguys/map';
import { MatDialog } from '@angular/material/dialog';
import {
  CapturesService,
  MissionsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { DeliverableDto } from '../../../models/products-deliverables/deliverable-dto';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { CaptureInfoDto } from '../../models/capture-with-deliverables-dto';
import { DeliverableStatus } from '../../models/deliverable-status.enum';
import { ColumnColoringRules } from '../../../components/columns/components/column-stylizer/column-stylizer.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { OptionsFilter } from 'projects/file-management/src/lib/models/folder-permissions/optionsFilter.model';
import {
  CaptureAttributesDto,
  CaptureDeliverablesAttributesDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { AttributeTypesEnum } from '../../models/attribute-types.enum';
import { AttributeBooleanEnum } from '../../models/attribute-boolean.enum';

import { ExifData, parseToExif, FlyguysMarkerModalComponent } from '@flyguys/modals';
import { StatusService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { format } from 'date-fns';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { OAuthService } from 'angular-oauth2-oidc';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';
import { ConfirmDialogComponent } from '../../../components/common/confirm-dialog/confirm.dialog.component';
import { AttributeValidationService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals/attribute-validation.service';
import {
  AutomaticQaQcResultDto,
  ExifDataResultsDto,
  ValidationResultDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { AutomaticQaStatus } from '../../models/automatic-qa-status.enum';
import { ValidationsResultsModalComponent } from '../deliverables/validations-results-modal/validations-results-modal.component';
import { ToleranceTypeEnum } from '../../../../../../missions-service/src/lib/proxy/missions-service/basics/tolerance-type.enum';
import { ParallelFileUploadService } from 'projects/file-management/src/lib/services/parallel-file-upload.service';

@Component({
  selector: 'app-deliverable-files',
  templateUrl: './deliverable-files.component.html',
  styleUrls: ['./deliverable-files.component.scss'],
  providers: [
    DeleteService,
    DownloadService,
    DirectoryTreeService,
    NavigatorService,
    MoveService,
    UploadService,
    UpdateStreamService,
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eFileManagementComponents.FolderContent,
    },
  ],
})
export class DeliverableFilesComponent implements OnInit, OnDestroy, OnChanges {
  private readonly loadingDelay: number = 4000;
  private readonly loadingDelay1Second: number = 1000;
  public readonly rejectedCaptureStatus = DeliverableStatus.Rejected;

  currentContent: DirectoryContentDto[] = [];
  @Input() missionFilter: FilterMission;
  @Input() showMapIcon: boolean = true;
  @Input() deliverable: DeliverableDto;
  @Input() missionStatusId: string;
  @Input() deliverables: DeliverableDto[];
  @Input() allowToAccept: boolean = false;
  @Input() allowToReject: boolean = false;
  @Input() isSharedLink: boolean = false;
  @Input() missionName: string;
  @Input() acceptQuestion: string;
  @Input() acceptTitle: string;
  @Output() nameInteracted = new EventEmitter<void>();

  @ViewChild(FlyguysMapComponent) map: FlyguysMapComponent;
  displayMap = false;

  isModalOpen = false;
  contentReady: boolean;

  captureList: CaptureInfoDto[];
  selectedCapture: CaptureInfoDto;
  captureDate: Date;
  statusRules: ColumnColoringRules[] = [];
  filesSelected: FileSelected[] = [];
  isMoveFolderModalOpen = false;
  isMoveFolderModalBusy = false;
  optionsFilter: OptionsFilter = {
    move: false,
    rename: false,
    download: false,
    delete: false,
  };
  onlyViewDownload: boolean = true;
  permissionsLoaded: boolean = false;

  private modalPath: { path: string; pathId: string }[] = [];
  private moveModalHistory: any[] = [];
  private itemMoved: Subject<any> = new Subject();
  private itemTried: Subject<any> = new Subject();

  attributeList: Array<CaptureAttributesDto>;

  currentUserId: string;

  disableActions = false;
  currentToken: string;
  currentAttributes: Array<CaptureDeliverablesAttributesDto>;

  public readonly ALTITUDE_ATTR_NAME = 'Altitude';
  public readonly GRIMBALANGLE_ATTR_NAME = 'Grimbalangle';
  public readonly OVERLAP_ATTR_NAME = 'Overlap';
  private readonly IMAGE_QUANTITY_VALIDATOR_CODE = 'quantity';
  private readonly DATA_EXISTS_VALIDATOR_CODE = 'data exists validator';

  public alt_value: string;
  public gr_value: string;
  public ovlp_value: string;
  private subscriptions = new Subscription();

  action: any;
  acceptAction: any;

  downloadCompleteFolder: SelectedAllDto;
  validationClass: string;
  validationText: string;

  public contextMenuItems: FlyguysMapContextMenuItem[] = [
    {
      icon: 'visibility',
      text: 'Preview',
      action: (m: FlyguysMapMarker) => this.handleMarkerClick(m),
    },
    {
      icon: 'warning',
      text: 'QA Details',
      action: (m: FlyguysMapMarker) => this.getQaDetails(m),
    },
    {
      icon: 'download',
      text: 'Download',
      action: (m: FlyguysMapMarker) => this.downloadCapture(m),
    },
    {
      icon: 'delete',
      text: 'Delete Capture',
      action: (m: FlyguysMapMarker) => this.deleteCapture(m),
    },
  ];

  constructor(
    private updateStream: UpdateStreamService,
    private directoryService: DirectoryDescriptorService,
    private missionService: MissionsService,
    private navigator: NavigatorService,
    private snackBar: MatSnackBar,
    readonly dialog: MatDialog,
    private toaster: ToasterService,
    private missionFlowService: MissionFlowService,
    private spinner: NgxSpinnerService,
    private readonly filesService: FileDescriptorService,
    private captureService: CapturesService,
    private fileService: FileDescriptorService,
    private localizationService: LocalizationService,
    private stateService: ConfigStateService,
    private statusService: StatusService,
    private confirmation: ConfirmationService,
    private oAuthService: OAuthService,
    private notificationBroadcastService: NotificationBroadcastService,
    public readonly attributeValidationService: AttributeValidationService,
    private cdr: ChangeDetectorRef,
    private dialogService: MatDialog,
    public paralledFileUploadService: ParallelFileUploadService
  ) { }

  ngOnInit(): void {
    this.initialize();

    const currentRoles = this.stateService.getDeep('currentUser.roles') as string[];
    this.currentUserId = this.stateService.getDeep('currentUser')?.id;

    this.currentToken = this.oAuthService.getAccessToken();

    this.onlyViewDownload = !currentRoles.includes('admin');

    if (this.onlyViewDownload) {
      const allBeforeInvoiced = [
        'pilot success',
        'qa qc agent',
        'qa qc manager',
        'qa qc supervisor',
        'qaqc',
        'mission coordinator',
        'mission coordinator manager',
        'pilot success manager',
      ];

      if (!this.isSharedLink) {
        this.statusService.get(this.missionStatusId).subscribe(q => {
          if (q.code != 'CI' && currentRoles.some(q => allBeforeInvoiced.includes(q)))
            this.onlyViewDownload = false;

          this.permissionsLoaded = true;
        });
      }
    } else this.permissionsLoaded = true;

    this.loadCaptureList();
    if (this.isDeliverableStatusAwaiting(this.deliverable?.status)) {
      this.optionsFilter.move = true;
    }

    this.loadCaptureAttributeList();

    this.fetchMissionDeliverableAttributesData();

    this.subscriptions.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (
          notif.notificationKey ==
          enumWebBackgroundNotificationKey.EventForMissionDetailDeliverableGrid
        ) {
          if (notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForTable) {
            if (
              notif.itemId &&
              notif.itemId.toLocaleLowerCase() ==
              this.missionFilter.missionId.toLocaleLowerCase() &&
              notif.extraArgument.deliverableInfo
            ) {
              let newInfo = notif.extraArgument.deliverableInfo.find(
                y =>
                  y.deliverableId.toLocaleLowerCase() ==
                  this.deliverable.deliverableId.toLocaleLowerCase() &&
                  y.orderDetailId.toLocaleLowerCase() ==
                  this.deliverable.orderDetailId.toLocaleLowerCase(),
              );

              if (!newInfo) return;

              if (newInfo.status == DeliverableStatus.Accepted) {
                let alreadyApprovedCapture = this.captureList.find(
                  x => x.captureDeliverableStatus == DeliverableStatus.Accepted,
                );

                if (alreadyApprovedCapture)
                  alreadyApprovedCapture.captureDeliverableStatus = DeliverableStatus.Rejected;

                this.snackBar.open(
                  `Capture ${this.selectedCapture.captureNumber} approved.`,
                  'Close',
                  {
                    duration: this.loadingDelay,
                  },
                );
              }

              if (newInfo.status == DeliverableStatus.Rejected) {
                this.snackBar.open(
                  `Capture ${this.selectedCapture.captureNumber} rejected.`,
                  'Close',
                  {
                    duration: this.loadingDelay,
                  },
                );
              }

              if (newInfo.status == DeliverableStatus.Awaiting) {
                this.snackBar.open(
                  `Capture ${this.selectedCapture.captureNumber} awaiting.`,
                  'Close',
                  {
                    duration: this.loadingDelay,
                  },
                );
              }

              this.selectedCapture.captureDeliverableStatus = newInfo.status;
              this.loadCaptureList();
            }

            if (
              notif.itemId &&
              notif.itemId.toLocaleLowerCase() ==
              this.missionFilter.missionId.toLocaleLowerCase() &&
              notif.extraArgument?.refreshAutoQAQC &&
              notif.extraArgument?.orderDetailId
            ) {
              this.updateValidationResults(notif.extraArgument.orderDetailId);
            }
          }
        }
      }),
    );

    this.validationClass = this.getQValidationClass();
    this.validationText = this.getQValidationText();
  }

  setAction(act: any) {
    this.action = act;
  }

  setAcceptAction(act: any) {
    this.acceptAction = act;
  }

  public updateDeliverableToAwait() {
    this.disableActions = true;

    this.confirmation
      .warn(
        this.localizationService.instant('missionsService::AwaitingUploadWarning'),
        'missionsService::AreYouSure',
        {
          messageLocalizationParams: [],
        },
      )
      .subscribe(status => {
        if (status === Confirmation.Status.confirm) {
          this.missionService
            .updateDeliverableStatus({
              missionId: this.selectedCapture.missionId,
              orderDetailId: this.deliverable.orderDetailId,
              deliverableId: this.deliverable.deliverableId,
              status: DeliverableStatus.Awaiting,
              captureId: this.selectedCapture.id,
              creatorId: this.currentUserId,
            })
            .subscribe({
              next: () => {
                this.disableActions = false;
              },
              error: error => {
                this.disableActions = false;
              },
            });
        } else {
          this.disableActions = false;
        }
      });
  }

  public getDeliverableStatusClass(status: number): string {
    if (!status && status != 0) return '';

    return `badge badge-deliverable-${DeliverableStatus[status].toLowerCase()}`;
  }

  public getDeliverableStatusText(status: number): string {
    if (!status && status != 0) return;
    const statusDescription = DeliverableStatus[status];

    return statusDescription.toLowerCase().includes('awaiting')
      ? this.localizationService.instant('missionsService::AwaitingUpload')
      : statusDescription;
  }

  public onChangeCapture(captureId): void {
    this.contentReady = false;

    this.selectedCapture = this.captureList.find(x => x.id == captureId);
    this.ConvertDate(this.selectedCapture);

    this.filesSelected = [];

    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliverable) {
      this.validationClass = this.getQValidationClass();
      this.validationText = this.getQValidationText();
      this.cdr.detectChanges();
    }
  }

  getQValidationText(): string {
    const status = this.deliverable.automaticQa as AutomaticQaStatus;

    switch (status) {
      case AutomaticQaStatus.Fail:
        return 'Fail';
      case AutomaticQaStatus.Pass:
        return 'Pass';
      case AutomaticQaStatus.Manual:
        return 'Manual';
      case AutomaticQaStatus.NotTested:
        return 'Not Tested';
      default:
        return 'Unknown';
    }
  }

  getQValidationClass(): string {
    const status = this.deliverable.automaticQa as AutomaticQaStatus;

    return `badge badge-automatic-qa-${AutomaticQaStatus[status]?.toLocaleString()?.toLowerCase()}`;
  }

  openValidationResultsDialog(): void {
    if (this.deliverable.automaticQa === AutomaticQaStatus.NotTested) {
      this.toaster.info('No validation results available for this deliverable.');
      return;
    }

    this.dialogService.open(ValidationsResultsModalComponent, {
      width: '1150px',
      data: {
        results: this.deliverable.validationResults,
        isSingleFile: false,
      },
    });
  }

  private initialize(): void {
    this.spinner.show();

    this.directoryService.setCurrentCapture(this.missionFilter.captureId);

    this.contentReady = false;
    this.snackBar.open('Loading file structure', 'Loading', {
      duration: this.loadingDelay,
    });

    this.directoryService
      .getRoot(
        this.missionFilter.missionId,
        this.missionFilter.deliverableId,
        this.missionFilter.captureId,
        this.missionFilter.orderDetailId,
      )
      .subscribe({
        next: (folder: DirectoryDescriptorDto) => {
          if (!folder) {
            this.snackBar.open('No file structure found for this deliverable', 'Alert', {
              duration: this.loadingDelay,
            });
            this.spinner.hide();
            return;
          }

          this.updateStream.patchStore({
            currentDirectory: folder.id,
          });

          this.navigateStartToFolder(folder.id, folder.name);

          this.missionFilter.rootFolder = { id: folder.id, name: folder.name };

          this.updateStream.refreshContent();

          this.contentReady = true;

          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        },
      });
  }

  goBack() {
    this.nameInteracted.emit();
  }

  navigateStartToFolder(id: string, name: string) {
    setTimeout(() => {
      this.navigator.goToFolder({ id: id, name: name });
      this.snackBar.dismiss();
    }, this.loadingDelay);
  }

  async createFolder(
    parent: string,
    name: string,
    withMissionFilter: boolean = false,
  ): Promise<string> {
    const subFolders = await lastValueFrom(this.directoryService.getList(parent));

    const fFound = subFolders.items?.find(r => r.name == name);

    if (!fFound) {
      if (!withMissionFilter)
        return (
          (await lastValueFrom(this.directoryService.create({ parentId: parent, name: name })))
            .id || ''
        );

      const data = (
        await lastValueFrom(
          this.directoryService.createWithMissionFilters(
            { parentId: parent, name: name },
            this.missionFilter,
          ),
        )
      )[0];

      return data.id || '';
    }

    return fFound.id;
  }

  send: DirectoryContentDto[] = [];
  loadingMap: boolean;

  async toggleMap(show: boolean) {
    if (show) {
      this.loadingMap = true;
      this.displayMap = !this.displayMap;
      if (!this.displayMap) {
        this.map.clearMarkers();
        // this.mapMarkers = [];
      } else {
        this.snackBar.open('Loading map', 'Ok', { duration: this.loadingDelay });
        setTimeout(() => {
          this.loadingMap = false;
        }, this.loadingDelay);
      }

      await this.delay(this.loadingDelay1Second);

      this.loadKML();
      this.mapLocations();
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  mapLocations() {

    if (!this.currentContent || this.currentContent?.length === 0)
      return;

    const isRootFolder = this.currentContent.some(p => p.isDirectory == true);
    let missionFilesTotal = 0;
    if (!isRootFolder) {
      let parentId = this.currentContent.find(p => p.isDirectory == false).parentId;
      let directoryContentRequestInput: DirectoryContentRequestInput = {
        id: parentId,
        maxResultCount: 1000,
        skipCount: 0,
      };

      this.directoryService.getContent(directoryContentRequestInput).subscribe({
        next: async (response: PagedResultDto<DirectoryContentDto>) => {
          missionFilesTotal = response.totalCount;

          let request = {
            filter: '',
            id: parentId,
            sorting: '',
            skipCount: 0,
            maxResultCount: 1000,
          } as DirectoryContentRequestInput;

          let itemsDirectoryContentDto: DirectoryContentDto[];
          let isDone = false;
          while (!isDone) {
            const page = await firstValueFrom(this.directoryService.getContent(request));
            if (itemsDirectoryContentDto === undefined) {
              itemsDirectoryContentDto = page.items;
            } else {
              itemsDirectoryContentDto.push(...page.items);
            }
            request.skipCount += page.items.length;

            if (request.skipCount >= missionFilesTotal) {
              isDone = true;
            }
          }

          itemsDirectoryContentDto.forEach((image, index) => {
            if (image.exifData != null) {
              const exifData: ExifData = parseToExif(image.exifData);

              const marker = {
                id: index,
                lat: Number(exifData.lat),
                lng: Number(exifData.lng),
                description: {
                  exif: exifData,
                  resource: {
                    id: image.id,
                    path: image.imagePreviewUrl,
                    name: image.name,
                  },
                },
              } as FlyguysMapMarker;

              this.map.addMarker(marker);
            }
          });

          this.map.centerMap();
        },
        error: error => console.error('Unable to get this.directoryService.getContent \n', error),
      });
    } else {
      let parentId = this.currentContent.find(p => p.isDirectory == true).parentId;

      let directoryContentRequestInput: DirectoryContentRequestInput = {
        id: parentId,
        maxResultCount: 1000,
        skipCount: 0,
      };
      this.directoryService.getContent(directoryContentRequestInput).subscribe({
        next: async (response: PagedResultDto<DirectoryContentDto>) => {
          missionFilesTotal = response.totalCount;

          let request = {
            filter: '',
            id: parentId,
            sorting: '',
            skipCount: 0,
            maxResultCount: 1000,
          } as DirectoryContentRequestInput;

          let itemsDirectoryContentDto: DirectoryContentDto[];
          let isDone = false;
          while (!isDone) {
            const page = await firstValueFrom(this.directoryService.getContent(request));
            if (itemsDirectoryContentDto === undefined) {
              itemsDirectoryContentDto = page.items;
            } else {
              itemsDirectoryContentDto.push(...page.items);
            }
            request.skipCount += page.items.length;

            if (request.skipCount >= missionFilesTotal) {
              isDone = true;
            }
          }

          itemsDirectoryContentDto.forEach((image, index) => {
            if (image.exifData != null) {
              const exifData: ExifData = parseToExif(image.exifData);

              const marker = {
                id: index,
                lat: Number(exifData.lat),
                lng: Number(exifData.lng),
                description: {
                  exif: exifData,
                  resource: {
                    id: image.id,
                    path: image.imagePreviewUrl,
                    name: image.name,
                  },
                },
              } as FlyguysMapMarker;

              this.map.addMarker(marker);
            }
          });

          this.map.centerMap();
        },
        error: error => console.error('Unable to get this.directoryService.getContent \n', error),
      });
    }
  }

  loadKML() {
    let kmlAttributes = this.attributeList?.filter(
      x => x.attributeCodeType == AttributeTypesEnum.Kml,
    );

    const urlsArray: string[] = [];
    let mapComponent = this.map;

    if (kmlAttributes?.length > 0) {
      const first20Attributes = kmlAttributes.slice(0, 20);
      first20Attributes.forEach(kmlAtt => {
        if (kmlAtt.fileLink) {
          urlsArray.push(kmlAtt.fileLink);
        }
      });
      const remainingAttributes = kmlAttributes.slice(20);
      if (remainingAttributes.length > 0) {
        const remainingFileNames = remainingAttributes.map(attr => attr?.fileName).join(', ');

        this.snackBar.open(`The following KML are not displayed: ${remainingFileNames}`, 'Ok', {
          duration: this.loadingDelay,
        });
      }

      mapComponent?.loadMultipleKMLLayers(urlsArray);

      mapComponent.centerMap();
    }
  }

  handleMarkerClick(marker: FlyguysMapMarker): void {
    this.dialog.open(FlyguysMarkerModalComponent, {
      width: '400px',
      data: marker.description,
    });
  }

  formatTime(time: string): string {
    if (!time) return '--:--';

    const [hours, minutes] = time.split(':');
    const formattedHours = parseInt(hours, 10) > 12 ? parseInt(hours, 10) - 12 : hours;
    const amOrPm = parseInt(hours, 10) >= 12 ? 'PM' : 'AM';
    return `${formattedHours}:${minutes} ${amOrPm}`;
  }

  private loadCaptureList(): void {
    this.missionFlowService
      .getByMissionAndDeliverableIdAsync(
        this.missionFilter.missionId,
        this.missionFilter.deliverableId,
        this.missionFilter.orderDetailId,
      )
      .subscribe((res: CaptureInfoDto[]) => {
        this.captureList = res;
        this.selectedCapture = this.captureList.find(x => x.id == this.missionFilter.captureId);
        this.ConvertDate(this.selectedCapture);
      });
  }

  ConvertDate(data: CaptureInfoDto) {
    const date: Date = data.date instanceof Date ? data.date : new Date(data.date);
    const newDate = `${format(date, 'yyyy-MM-dd')} ${data.time ?? '00:00'}`;
    this.captureDate = new Date(newDate);
  }

  updateFilesToDownload(event: FileSelected[]) {
    this.filesSelected = event;
  }

  downloadSelectedFiles(): void {
    if (this.downloadCompleteFolder) {
      this.generateZipFile(this.downloadCompleteFolder.folderId, this.deliverable);
      return;
    }

    if (this.filesSelected.length > 0) {
      const filesIds = this.filesSelected.map(x => x.item.id);
      this.generateZipFile(filesIds.join(','), this.deliverable);
    } else {
      this.snackBar.open('No files selected', 'Close', {
        duration: this.loadingDelay,
      });
    }
  }

  handleOnSelectAllChange(event: SelectedAllDto) {
    if (event.isSelected) {
      this.downloadCompleteFolder = { ...event };
      return;
    }

    this.downloadCompleteFolder = undefined;
  }

  generateZipFile(filesIds: string, deliverable: DeliverableDto) {
    this.fileService.validateContent(filesIds).subscribe({
      next: (hasContent: boolean) => {
        if (!hasContent) {
          this.snackBar.open('Folder has no content', 'close', {
            duration: this.loadingDelay,
          });
          return;
        }
        this.fileService.getDownloadTokenZip().subscribe({
          next: (response: ZipDownloadResult) => {
            this.fileService.downloadZipFileUrl(
              response.url,
              response.token,
              filesIds,
              this.missionFilter?.missionId,
              deliverable,
            );
          },
          error: error => console.error('Unable to get token:\n', error),
        });
      },
      error: error => console.error('Unable to validate content:\n', error),
    });
  }

  showMoveModal(): void {
    if (!this.isDeliverableStatusAwaiting(this.deliverable?.status)) {
      this.snackBar.open('Can not move deliverable', 'Close', {
        duration: this.loadingDelay,
      });
      return;
    }
    this.isMoveFolderModalOpen = true;
    if (this.deliverables.length <= 0) {
      this.snackBar.open('No deliverables found', 'Error', {
        duration: this.loadingDelay,
      });
      return;
    }
    this.modalPath = [];
    this.moveModalHistory = [];
    this.moveModalHistory.push(this.deliverables);
  }

  goToContent(item: any): void {
    if (item.deliverableId && item.lastCaptureId && item.orderDetailId) {
      this.modalPath.push({
        path: item.deliverableName,
        pathId: item.deliverableId,
      });
      this.isMoveFolderModalBusy = true;
      this.directoryService
        .getRoot(
          this.missionFilter.missionId,
          item.deliverableId,
          item.lastCaptureId,
          item.orderDetailId,
        )
        .subscribe({
          next: response => {
            if (!response) {
              this.snackBar.open(
                `Deliverable: ${item.deliverableName} does not have a	root folder`,
                'Error',
                {
                  duration: this.loadingDelay,
                },
              );
              this.modalPath = [];
              this.isMoveFolderModalBusy = false;
            } else {
              this.goToContent(response);
            }
          },
          error: error => {
            this.isMoveFolderModalBusy = false;
            console.error('Unable to get root folder:\n', error);
          },
        });
    } else if (item.isDirectory || (item.name && item.parentId == null)) {
      this.modalPath.push({ path: item.name, pathId: item.id });
      const directoryContentRequest = {
        id: item.id,
      } as DirectoryContentRequestInput;
      this.directoryService.getContent(directoryContentRequest).subscribe({
        next: response => {
          this.moveModalHistory.push(response.items.filter(x => x.isDirectory));
          this.isMoveFolderModalBusy = false;
        },
        error: error => {
          this.isMoveFolderModalBusy = false;
          console.error('Unable to get content:\n', error);
        },
      });
    } else {
    }
  }

  navigateToPath(pathIndex: number): void {
    try {
      if (pathIndex < 0 || pathIndex >= this.modalPath.length - 1) {
        return;
      }
      if (pathIndex === 0) {
        this.modalPath = [];
        this.moveModalHistory = this.moveModalHistory.slice(0, 1);
        return;
      }
      this.modalPath = this.modalPath.slice(0, pathIndex + 1);
      this.moveModalHistory = this.moveModalHistory.slice(0, pathIndex + 1);
    } catch (error) {
      console.error('Unable to navigate to folder:\n', error);
    }
  }

  moveSelectedItems() {
    this.snackBar.open('Moving items... Please wait', 'Close', {
      duration: this.loadingDelay,
    });
    try {
      if (this.moveModalHistory.length <= 1 || this.modalPath.length < 1) {
        this.snackBar.open('You can not move items here', 'Error', {
          duration: this.loadingDelay,
        });
        return;
      }

      const moveToId = this.modalPath[this.modalPath.length - 1].pathId;
      if (!moveToId) {
        this.snackBar.open('Invalid folder Id', 'Close', {
          duration: this.loadingDelay,
        });
        return;
      }

      if (this.downloadCompleteFolder) {
        const moveDirectoryInput: MoveDirectoryInput = {
          id: this.downloadCompleteFolder.folderId,
          newParentId: moveToId,
        };
        this.directoryService.moveContent(moveDirectoryInput).subscribe({
          next: response => {
            this.goBack();
            this.snackBar.open('Items moved', 'Close', {
              duration: this.loadingDelay,
            });
          },
          error: error => {
            this.snackBar.open(`Unable to move files`, 'Error', {
              duration: this.loadingDelay,
            });
          },
        });

        return;
      }

      const folders = this.filesSelected.filter(x => x.item.isDirectory && x.isSelected);
      const files = this.filesSelected.filter(x => !x.item.isDirectory && x.isSelected);
      if (files.length === 0 && folders.length === 0) {
        this.snackBar.open('No files/folders to move', 'Close', {
          duration: this.loadingDelay,
        });
      }
      let foldersCount = folders.length;
      let filesCount = files.length;

      let foldersTried = 0;
      let filesTried = 0;
      folders.forEach(folder => {
        const moveDirectoryInput: MoveDirectoryInput = {
          id: folder.item.id,
          newParentId: moveToId,
        };
        this.directoryService.move(moveDirectoryInput).subscribe({
          next: response => {
            foldersCount--;
            this.itemMoved.next(response);
          },
          error: error => {
            console.error(`Unable to move folder ${folder.item.name}:\n`, error);
          },
          complete: () => {
            foldersTried++;
            this.itemTried.next(true);
          },
        });
      });
      files.forEach(file => {
        const moveFileInput: MoveFileInput = {
          id: file.item.id,
          newDirectoryId: moveToId,
        };
        this.filesService.move(moveFileInput).subscribe({
          next: response => {
            filesCount--;
            this.itemMoved.next(response);
          },
          error: error => {
            console.error(`Unable to move file ${file.item.name}:\n`, error);
          },
          complete: () => {
            filesTried++;
            this.itemTried.next(true);
          },
        });
      });
      this.itemTried.asObservable().subscribe(async () => {
        if (foldersTried == folders.length && filesTried == files.length) {
          this.goBack();
          if (files.length > 0)
            this.snackBar.open(
              `Moved ${files.length - filesCount} of ${files.length} files`,
              'Close',
              {
                duration: this.loadingDelay,
              },
            );
          await new Promise(f => setTimeout(f, this.loadingDelay));
          if (folders.length > 0)
            this.snackBar.open(
              `Moved ${folders.length - foldersCount} of ${folders.length} folders`,
              'Close',
              {
                duration: this.loadingDelay,
              },
            );
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  isDeliverableStatusAwaiting(status: string): boolean {
    return Number(status) === DeliverableStatus.Awaiting;
  }

  public downloadFromBlobStorage(link, fileName) {
    fetch(link)
      .then(response => response.blob())
      .then(blobresp => {
        const blob = new Blob([blobresp], { type: 'octet/stream' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.download = fileName;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  public canReject(status: number): boolean {
    return (
      (status == DeliverableStatus.Accepted || status == DeliverableStatus.Awaiting) &&
      this.allowToReject
    );
  }

  public canAccept(status: number): boolean {
    return (
      (status == DeliverableStatus.Rejected || status == DeliverableStatus.Awaiting) &&
      this.allowToAccept
    );
  }

  public canReset(status: number): boolean {
    return (
      (status == DeliverableStatus.Accepted || status == DeliverableStatus.Rejected) &&
      (this.allowToAccept || this.allowToReject)
    );
  }

  public navigateToFolder(event: any) {
    this.navigator.goToFolder(event);

    this.directoryService.announceBreadcrumbNavigation();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private loadCaptureAttributeList(): void {
    this.captureService
      .getAttributeValues(
        this.missionFilter.captureId,
        this.missionFilter.deliverableId,
        this.missionFilter.orderDetailId,
      )
      .subscribe((res: CaptureAttributesDto[]) => {
        this.attributeList = res;

        for (let attr of this.attributeList) {
          if (
            attr.attributeCodeType == AttributeTypesEnum.File ||
            attr.attributeCodeType == AttributeTypesEnum.Kml
          ) {
            this.getAttributeFileDetails(attr);
          }

          if (attr.attributeCodeType == AttributeTypesEnum.Bool) {
            attr.value =
              attr.value == AttributeBooleanEnum.True
                ? this.localizationService.instant('missionsService::Yes')
                : this.localizationService.instant('missionsService::No');
          }
        }
      });
  }

  private getAttributeFileDetails(attribute: CaptureAttributesDto) {
    this.fileService.get(attribute.value).subscribe(res => {
      attribute.fileName = res.name;
      attribute.fileLink = res.fileAttachmentUrl;
    });
  }

  private fetchMissionDeliverableAttributesData(): void {
    this.missionService.getAttributeValues(this.missionFilter.missionId).subscribe(res => {
      this.currentAttributes = res;

      this.mapAttributes(this.deliverable);
    });
  }

  private mapAttributes(deliverable: DeliverableDto): void {
    let altAttr = this.currentAttributes.find(
      t =>
        t.deliverableId == deliverable.deliverableId && t.attributeName == this.ALTITUDE_ATTR_NAME,
    );
    let gimbAttr = this.currentAttributes.find(
      t =>
        t.deliverableId == deliverable.deliverableId &&
        t.attributeName == this.GRIMBALANGLE_ATTR_NAME,
    );
    let overlapAttr = this.currentAttributes.find(
      t =>
        t.deliverableId == deliverable.deliverableId && t.attributeName == this.OVERLAP_ATTR_NAME,
    );

    this.alt_value = altAttr?.attributeValue;
    this.gr_value = gimbAttr?.attributeValue;
    this.ovlp_value = overlapAttr?.attributeValue;
  }

  /**
   * Handles capture deletion by the context menu
   * @param marker FlyguysMapMarker
   */
  private deleteCapture(marker: FlyguysMapMarker) {
    this.subscriptions.add(
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'Delete Capture?',
            message: '',
            actions: {
              cancel: 'Cancel',
              confirm: 'Delete',
            },
          },
          disableClose: true,
          width: '400px',
        })
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            this.spinner.show('capture');

            this.subscriptions.add(
              this.fileService
                .delete(marker.description.resource.id)
                .pipe(finalize(() => this.spinner.hide('capture')))
                .subscribe({
                  next: _ => {
                    this.map.removeMarker(marker);

                    if (!this.map.hasMarkers()) {
                      this.toggleMap(true);
                      this.toaster.success(
                        'Capture removed. No more captures visible, returning to Folder View',
                      );
                    } else {
                      this.toaster.success('Capture removed');
                    }
                  },
                  error: (e: Error) => this.toaster.error(`Error deleting capture ${e.message}`),
                }),
            );
          }
        }),
    );
  }

  /**
   * Downloads a capture
   * @param marker FlyguysMapMarker
   */
  private downloadCapture(marker: FlyguysMapMarker) {
    this.downloadFromBlobStorage(
      marker.description.resource.path,
      marker.description.resource.name,
    );
  }

  private getQaDetails(marker: FlyguysMapMarker) {
    const fileId = marker.description.resource.id;
    let modalDataResultsDto: ExifDataResultsDto[];

    const validationResults = this.deliverable.validationResults.filter(r => r.fileId == fileId);
    if (validationResults?.length > 0) {
      modalDataResultsDto = validationResults;
      this.dialogService.open(ValidationsResultsModalComponent, {
        width: '1150px',
        data: {
          results: modalDataResultsDto,
          isSingleFile: true,
        },
      });
    } else {
      this.toaster.warn('No QA/QC validations found for this file.');
    }
  }

  private updateValidationResults(orderDetailId: string) {
    this.attributeValidationService
      .getResults(orderDetailId)
      .pipe(
        map((result: PagedResultDto<AutomaticQaQcResultDto>) => this.mapToExifDataResults(result)),
      )
      .subscribe((result: PagedResultDto<ExifDataResultsDto>) => {
        if (result?.totalCount > 0) {
          this.deliverable.validationResults = result.items;

          const allResults = this.processValidationResults(this.deliverable.validationResults);

          if (allResults.length === 0) {
            this.deliverable.automaticQa = AutomaticQaStatus.NotTested;
          } else if (allResults.some(item => item.result === AutomaticQaStatus.Fail)) {
            this.deliverable.automaticQa = AutomaticQaStatus.Fail;
          } else if (allResults.every(item => item.result === AutomaticQaStatus.Pass)) {
            this.deliverable.automaticQa = AutomaticQaStatus.Pass;
          } else {
            this.deliverable.automaticQa = AutomaticQaStatus.NotTested;
          }

          this.validationClass = this.getQValidationClass();
          this.validationText = this.getQValidationText();

          this.cdr.detectChanges();
        }
      });
  }

  mapToExifDataResults(
    input: PagedResultDto<AutomaticQaQcResultDto>,
  ): PagedResultDto<ExifDataResultsDto> {
    const groupedResults = input.items.reduce(
      (acc, item) => {
        if (!acc[item.fileId]) {
          acc[item.fileId] = [];
        }
        acc[item.fileId].push(item);
        return acc;
      },
      {} as Record<string, AutomaticQaQcResultDto[]>,
    );

    const mappedItems: ExifDataResultsDto[] = Object.entries(groupedResults).map(
      ([fileId, items]) => {
        const validationResults: ValidationResultDto[] = items.map(item => ({
          toleranceCode: item.attributeName || '',
          toleranceValue: parseFloat(item.toleranceValue || '0'),
          toleranceType: item.toleranceType || ToleranceTypeEnum.Value,
          fieldValue: item.fieldExpectedValue || '',
          fileValue: item.fileActualValue || '',
          result: item.automaticQaQcValue,
        }));

        const overallValidationResult = validationResults.some(
          vr => vr.result === AutomaticQaStatus.Fail,
        )
          ? AutomaticQaStatus.Fail
          : validationResults.some(vr => vr.result === AutomaticQaStatus.NotTested)
            ? AutomaticQaStatus.NotTested
            : AutomaticQaStatus.Pass;

        return {
          fileId,
          fileName: items[0].fileName || '',
          overallValidationResult,
          validationResults,
        };
      },
    );

    return {
      totalCount: mappedItems.length,
      items: mappedItems,
    };
  }

  private processValidationResults(results: ExifDataResultsDto[]): ValidationResultDto[] {
    const deliverableResults = this.extractDeliverableResults(results);
    const fileResults = this.filterFileResults(results);

    return [
      ...deliverableResults,
      ...fileResults.reduce(
        (acc, file) => acc.concat(file.validationResults),
        [] as ValidationResultDto[],
      ),
    ];
  }

  private extractDeliverableResults(results: ExifDataResultsDto[]): ValidationResultDto[] {
    const allDeliverableResults = results.reduce((acc, file) => {
      const deliverableResults = file.validationResults.filter(result =>
        this.shouldFilterOut(result.toleranceCode),
      );
      return acc.concat(deliverableResults);
    }, [] as ValidationResultDto[]);

    const quantityResult = this.selectMostRelevantResult(
      allDeliverableResults.filter(
        r => r.toleranceCode.toLowerCase().trim() === this.IMAGE_QUANTITY_VALIDATOR_CODE,
      ),
    );

    const dataExistsResult = this.selectMostRelevantResult(
      allDeliverableResults.filter(
        r => r.toleranceCode.toLowerCase().trim() === this.DATA_EXISTS_VALIDATOR_CODE,
      ),
    );

    return [quantityResult, dataExistsResult].filter(r => r !== null) as ValidationResultDto[];
  }

  private filterFileResults(results: ExifDataResultsDto[]): ExifDataResultsDto[] {
    return results.map(file => ({
      ...file,
      validationResults: file.validationResults.filter(
        result => !this.shouldFilterOut(result.toleranceCode),
      ),
    }));
  }

  private selectMostRelevantResult(results: ValidationResultDto[]): ValidationResultDto | null {
    if (results.length === 0) return null;

    const passResults = results.filter(r => r.result === AutomaticQaStatus.Pass);

    if (passResults.length > 0) {
      return passResults.sort((a, b) => {
        const aValue = parseFloat(a.fileValue || '0');
        const bValue = parseFloat(b.fileValue || '0');
        return bValue - aValue;
      })[0];
    } else {
      return results[results.length - 1];
    }
  }

  private shouldFilterOut(toleranceCode: string): boolean {
    const lowerCaseTrimmedCode = toleranceCode.toLowerCase().trim();
    return (
      lowerCaseTrimmedCode === this.IMAGE_QUANTITY_VALIDATOR_CODE ||
      lowerCaseTrimmedCode === this.DATA_EXISTS_VALIDATOR_CODE
    );
  }
}
