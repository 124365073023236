import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  NotificationAudience,
  NotificationsCreateDto,
  NotificationsDto,
  NotificationsExcelDownloadDto,
  NotificationsManagerDto,
  SmsNotificationInput,
  SmsNotificationsMultipleCreateDto,
} from '../../basics/models';
import type { DownloadTokenResultDto } from '../../shared/models';
import { NotificationFilters } from 'projects/missions-service/src/lib/basics/missions/components/models/notification-filters';
import { PaginationSortingAndGlobalSearch } from 'projects/flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';
import { NotificatioManagerStatusFilterTypeEnum } from '../../shared/otificatio-manager-status-filter-type-enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  apiName = 'notificationsService';

  create = (input: NotificationsCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, NotificationsDto>(
      {
        method: 'POST',
        url: '/api/notification-service/sms',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  createMultipleOptions = (
    input: SmsNotificationsMultipleCreateDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, NotificationsDto>(
      {
        method: 'POST',
        url: '/api/notification-service/sms/multiple',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>(
      {
        method: 'GET',
        url: '/api/notification-service/sms/download-token',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: SmsNotificationInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<NotificationsDto>>(
      {
        method: 'GET',
        url: '/api/notification-service/sms',
        params: {
          filterText: input.filterText,
          userId: input.userId,
          notificationId: input.notificationId,
          message: input.message,
          sender: input.sender,
          status: input.status,
          description: input.description,
          recipients: input.recipients,
          resultInformation: input.resultInformation,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getManagerList = (
    filters: NotificationFilters,
    paginationSortingAndSearch: PaginationSortingAndGlobalSearch,
    customerId: string,
    missionStatus: number,
    notificatioManagerStatusFilterType: NotificatioManagerStatusFilterTypeEnum,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<NotificationsManagerDto>>(
      {
        method: 'POST',
        url: '/api/notification-service/notification-definitions/list',
        body: {
          ...filters,
          paginationSortingAndSearch,
          customerId,
          missionStatus,
          notificatioManagerStatusFilterType,
        },
      },
      { apiName: this.apiName, ...config },
    );

  enableDisableNotification = (
    notificationId: string,
    customerId: string,
    status: number,
    enable: boolean,
    notificatioManagerStatusFilterType: NotificatioManagerStatusFilterTypeEnum,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/notification-service/notification-definitions/disable-enable-notification',
        body: {
          notificationId,
          customerId,
          status,
          enable,
          notificatioManagerStatusFilterType,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getLogsList = (
    filters: NotificationFilters,
    missionId: string,
    paginationSortingAndSearch: PaginationSortingAndGlobalSearch,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<NotificationsDto>>(
      {
        method: 'POST',
        url: '/api/notification-service/notification/list',
        body: {
          ...filters,
          paginationSortingAndSearch,
          missionId,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListAsExcelFile = (input: NotificationsExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>(
      {
        method: 'GET',
        responseType: 'blob',
        url: '/api/notification-service/sms/as-excel-file',
        params: {
          downloadToken: input.downloadToken,
          filterText: input.filterText,
          name: input.name,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getAudienceList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, Array<NotificationAudience>>(
      {
        method: 'GET',
        url: '/api/notification-service//notification-definitions/audience',
      },
      { apiName: this.apiName, ...config },
    );
  constructor(private restService: RestService) { }
}
