import { ListService } from '@abp/ng.core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DirectoryContentDto,
  FileSelected,
  FilterMission,
  SelectedAllDto,
} from '@volo/abp.ng.file-management/proxy';
import { NavigatorService } from '../../services/navigator.service';
import { OptionsFilter } from '../../models/folder-permissions/optionsFilter.model';
import { DeliverableDto } from 'projects/flyguys/src/app/models/products-deliverables/deliverable-dto';

@Component({
  selector: 'abp-file-management-folder-panel',
  templateUrl: './file-management-folder-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListService],
})
export class FileManagementFolderPanelComponent implements OnChanges {
  @Output() contentUpdate = new EventEmitter<DirectoryContentDto[]>();
  @Output() filesSelectedChange = new EventEmitter<FileSelected[]>();
  @Output() selectAllChange = new EventEmitter<SelectedAllDto>();

  @Input() filesSelected: FileSelected[];
  @Input() hideDirectoryView: boolean;
  @Input() isSharedLink: boolean;
  @Input() showOptions = false;
  @Input() missionFilter: FilterMission = undefined;
  @Input() optionsFilter: OptionsFilter = null;
  @Input() onlyViewDownload: boolean;
  @Input() deliverable: DeliverableDto;

  constructor(
    public navigator: NavigatorService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliverable) {
      this.cdr.detectChanges();
    }
  }
}
