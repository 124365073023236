import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatContactDto, ChatMessageDto } from 'projects/chat/src/lib/models';
import { ChatMessageSide } from 'projects/chat/src/lib/enums';
import { ChatContactsComponent } from 'projects/chat/src/lib/components';
import { ConfigStateService, HttpWaitService, trackBy } from '@abp/ng.core';
import { ChatConfigService, ChatMessage } from '@volo/abp.ng.chat/config';
import { Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { ConversationService } from 'projects/chat/src/lib/services/conversation.service';
import { CustomizerSettingsService } from '../../../components/tagus/customizer-settings/customizer-settings.service';
import { NgScrollbar } from 'ngx-scrollbar';

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();

@Component({
  selector: 'app-chat-pilot-modal',
  templateUrl: './chat-pilot-modal.component.html',
  styleUrls: ['./chat-pilot-modal.component.scss'],
})
export class ChatPilotModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild(NgScrollbar) scrollable: NgScrollbar;
  @ViewChildren('chatMessage') messages: QueryList<any>;

  selectedContact: ChatContactDto;

  unreadMessageCount = 0;
  userMessages = new Map<string, ChatMessageDto[]>();
  chatMessageSide = ChatMessageSide;
  message: string;
  sendOnEnter: boolean = true;
  loading: boolean;
  pagingLoading: boolean;
  allMessagesLoaded: boolean;
  clickedStartMessage: boolean = false;

  @ViewChild(ChatContactsComponent, { static: false })
  chatContactsComponent: ChatContactsComponent;

  trackByMessageDate = trackBy<ChatMessageDto>('messageDate');

  destroy$ = new Subject();

  get selectedContactMessages(): ChatMessageDto[] {
    return this.userMessages.get(this.selectedContact.userId) || [];
  }

  constructor(
    public dialogRef: MatDialogRef<ChatPilotModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dataMat: ChatContactDto,
    private conversationService: ConversationService,
    private chatConfigService: ChatConfigService,
    private configState: ConfigStateService,
    private httpWaitService: HttpWaitService,
    public themeService: CustomizerSettingsService,
  ) {
    this.selectedContact = dataMat;
    this.httpWaitService.addFilter([
      {
        method: 'POST',
        endpoint: '/api/chat/conversation/send-message',
      },
      {
        method: 'GET',
        endpoint: '/api/chat/contact/contacts',
      },
    ]);
  }

  private listenToChatBoxScroll() {
    this.scrollable.scrolled
      .pipe(debounceTime(150), takeUntil(this.destroy$))
      .subscribe(this.onScroll);
  }

  private listenToNewMessages() {
    this.chatConfigService.message$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ senderUserId, text } = {} as ChatMessage) => {
        if (!this.userMessages.has(senderUserId)) return;

        const isSelected = this.selectedContact.userId === senderUserId;
        this.userMessages.get(senderUserId).push({
          message: text,
          messageDate: new Date(),
          side: ChatMessageSide.Receiver,
          isRead: isSelected,
          readDate: isSelected ? new Date() : null,
        });
      });
  }

  ngAfterViewInit() {
    this.sendOnEnter =
      (
        this.configState.getSetting('Volo.Chat.Messaging.SendMessageOnEnter') || ''
      ).toLowerCase() !== 'false';

    this.listenToChatBoxScroll();
    this.listenToNewMessages();
    this.onSelectContact(this.selectedContact);

    this.messages.changes.subscribe({
      next: _ => this.scrollable.scrollToElement(this.messages.last?.nativeElement),
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getConversation() {
    this.allMessagesLoaded = false;
    this.conversationService
      .getConversationByInput({
        skipCount: 0,
        maxResultCount: 50,
        targetUserId: this.selectedContact.userId,
      })
      .subscribe(res => {
        if (
          res.messages.length < 1 &&
          this.selectedContact.missionDetails &&
          this.selectedContact.missionDetails === true &&
          this.selectedContact.firstMessage
        ) {
          this.message = this.selectedContact.firstMessage;
        }

        this.userMessages.set(this.selectedContact.userId, res.messages.reverse());
        if (this.selectedContact.unreadMessageCount) this.markConversationAsRead();
      });
  }

  send() {
    if (!this.message || this.loading) return;

    this.unreadMessageCount = 0;
    this.loading = true;
    this.conversationService
      .sendMessageByInput({ message: this.message, targetUserId: this.selectedContact.userId })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        if (!this.userMessages.has(this.selectedContact.userId)) {
          this.userMessages.set(this.selectedContact.userId, []);
        }

        this.userMessages.get(this.selectedContact.userId).push({
          message: this.message,
          isRead: true,
          readDate: new Date(),
          messageDate: new Date(),
          side: ChatMessageSide.Sender,
        });

        this.message = '';
      });
  }

  markConversationAsRead() {
    this.conversationService
      .markConversationAsReadByInput({ targetUserId: this.selectedContact.userId })
      .subscribe(() => {
        this.chatContactsComponent.markSelectedContactAsRead();
        this.selectedContact.unreadMessageCount = 0;
        setTimeout(() => (this.unreadMessageCount = 0), 5000);
      });
  }

  sendWithEnter(event: KeyboardEvent) {
    if (!this.sendOnEnter) return;

    event.preventDefault();
    this.send();
  }

  onSelectContact(contact: ChatContactDto) {
    this.selectedContact = contact;
    this.unreadMessageCount = contact.unreadMessageCount;

    if (this.userMessages.has(contact.userId)) {
      if (contact.unreadMessageCount) this.markConversationAsRead();
      return;
    }

    this.getConversation();
  }

  getDateFormat(date: string | Date): string {
    date = new Date(date);
    const messageDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();

    if (messageDay === today) return 'shortTime';

    return 'short';
  }

  onScroll = (event: Event) => {
    if (
      this.allMessagesLoaded ||
      this.pagingLoading ||
      !this.selectedContact.lastMessage ||
      this.selectedContactMessages.length % 50 !== 0
    ) {
      event.preventDefault();
      return;
    }

    this.pagingLoading = true;
    this.conversationService
      .getConversationByInput({
        skipCount: this.selectedContactMessages.length,
        maxResultCount: 50,
        targetUserId: this.selectedContact.userId,
      })
      .pipe(finalize(() => (this.pagingLoading = false)))
      .subscribe(res => {
        if (!res.messages.length) {
          this.allMessagesLoaded = true;
          return;
        }
        this.userMessages.get(this.selectedContact.userId).unshift(...res.messages.reverse());
      });
  };

  getDateInLocalTime(date: string | Date): Date {
    if (date instanceof Date) {
      return date;
    }

    let newDate: Date = new Date(date + 'Z');
    return newDate;
  }
}
