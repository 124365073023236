export enum enumWebBackgroundNotificationKey {
  EventForMissionGrid = 'EVENT_FOR_MISSION_ORDER_GRID',
  EventForMissionDetailDeliverableGrid = 'EVENT_FOR_MISSION_DETAIL_DELIVERABLE_GRID',
  EventGlobalForMissionStatus = 'EVENT_GLOBAL_FOR_MISSION_STATUS',
  EventGlobalForMissionAssignationUpdated = 'EVENT_GLOBAL_FOR_MISSION_ASSIGNATION_UPDATED',
  EventGlobalNoteAddedToMission = 'EVENT_GLOBAL_NOTE_ADDED_TO_MISSION',
  EventGlobalForMissionFinderStatus = 'EVENT_GLOBAL_FOR_MISSION_FINDER_STATUS',
  EventGlobalForPilotStatus = 'EVENT_GLOBAL_FOR_PILOT_STATUS',
  EventUserNotification = 'EVENT_USER_NOTIFICATION',
}
