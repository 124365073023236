import { mapEnumToOptions } from '@abp/ng.core';

export enum NotificatioManagerStatusFilterTypeEnum {
  WithStatus = 0,
  NoStatus = 1,
  CodeImplemented = 3,
}

export const EnumNotificationStatusOptions = mapEnumToOptions(
  NotificatioManagerStatusFilterTypeEnum,
);
