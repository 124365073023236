import type {
  CreateDirectoryInput,
  DirectoryContentDto,
  DirectoryContentRequestInput,
  DirectoryDescriptorDto,
  DirectoryDescriptorInfoDto,
  FolderPermissionResultModel,
  MoveDirectoryInput,
  RenameDirectoryInput,
  FilterMission,
  RootFolderDetailsDto,
  RootFoldersDetailsDto,
} from './models';
import { RestService } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirectoryDescriptorService {
  apiName = 'FileManagement';

  filterMission: FilterMission = undefined;
  currentCaptureId: string;
  navigationFromBreadcrumb$ = new Subject<void>();
  updateBreadcrumb$ = new Subject<void>();

  announceBreadcrumbNavigation(): void {
    this.navigationFromBreadcrumb$.next();
  }

  updateBreadcrumb() {
    this.updateBreadcrumb$.next();
  }

  setCurrentCapture(captureId: string) {
    this.currentCaptureId = captureId;
  }

  getCurrentCapture() {
    return this.currentCaptureId;
  }

  create = (input: CreateDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/directory-descriptor',
        body: input,
      },
      { apiName: this.apiName },
    );

  createIfNotExists = (input: CreateDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/directory-descriptor/createIfNotExists',
        body: input,
      },
      { apiName: this.apiName },
    );

  createWithMissionFilters = (input: CreateDirectoryInput, missionFilter: FilterMission) =>
    this.restService.request<string, any>(
      {
        method: 'POST',
        url: `/api/file-management/directory-descriptor/mission/${missionFilter.missionId}/capture/${missionFilter.captureId}/deliverable/${missionFilter.deliverableId}/structure`,
        body: `\"{ \\"FolderName\\":\\"${input.name}\\", \\"ParentId\\":\\"${input.parentId}\\"}\"`,
        headers: { 'Content-Type': 'application/json' },
      },
      { apiName: this.apiName },
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/file-management/directory-descriptor/${id}`,
      },
      { apiName: this.apiName },
    );

  deleteContent = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/file-management/directory-descriptor/parent/${id}/content`,
      },
      { apiName: this.apiName },
    );

  get = (id: string) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/${id}`,
      },
      { apiName: this.apiName },
    );

  getContent(input: DirectoryContentRequestInput, missionFilter: FilterMission = undefined) {
    let url = '/api/file-management/directory-descriptor';
    let params: any = {};

    if (missionFilter)
      url += `/mission/${missionFilter.missionId}/capture/${missionFilter.captureId}/deliverable/${missionFilter.deliverableId}/orderDetail/${missionFilter.orderDetailId}`;
    else
      params = {
        filter: input.filter,
        id: input.id,
        sorting: input.sorting,
        skipCount: input.skipCount,
        maxResultCount: input.maxResultCount,
      };

    return this.restService.request<any, PagedResultDto<DirectoryContentDto>>(
      {
        method: 'GET',
        url: url,
        params: params,
      },
      { apiName: this.apiName },
    );
  }

  getList = (parentId: string) =>
    this.restService.request<any, ListResultDto<DirectoryDescriptorInfoDto>>(
      {
        method: 'GET',
        url: '/api/file-management/directory-descriptor/sub-directories',
        params: { parentId },
      },
      { apiName: this.apiName },
    );

  getRoot = (missionId: string, deliverableId: string, captureId: string, orderDetailId: string) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/root/mission/${missionId}/deliverable/${deliverableId}/capture/${captureId}/orderDetail/${orderDetailId}`,
      },
      { apiName: this.apiName },
    );

  getRootFolderDetails = (
    missionId: string,
    deliverableId: string,
    captureId: string,
    orderDetailId: string,
  ) =>
    this.restService.request<any, RootFolderDetailsDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/root/details/mission/${missionId}/deliverable/${deliverableId}/capture/${captureId}/orderDetail/${orderDetailId}`,
      },
      { apiName: this.apiName },
    );

  getRootFolderDetailsCapWithOut = (
    missionId: string,
    deliverableId: string,
    orderDetailId: string,
  ) =>
    this.restService.request<any, RootFoldersDetailsDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/root/details/mission/${missionId}/deliverable/${deliverableId}/orderDetail/${orderDetailId}`,
      },
      { apiName: this.apiName },
    );

  move = (input: MoveDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/directory-descriptor/move',
        body: input,
      },
      { apiName: this.apiName },
    );

  moveContent = (input: MoveDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: '/api/file-management/directory-descriptor/move/content',
        body: input,
      },
      { apiName: this.apiName },
    );

  rename = (id: string, input: RenameDirectoryInput) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: `/api/file-management/directory-descriptor/${id}`,
        body: input,
      },
      { apiName: this.apiName },
    );

  getPermissions = (id: string) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'GET',
        url: `/api/file-management/directory-descriptor/${id}/permissions`,
      },
      { apiName: this.apiName },
    );

  updatePermissions = (id: string, input: FolderPermissionResultModel) =>
    this.restService.request<any, DirectoryDescriptorDto>(
      {
        method: 'POST',
        url: `/api/file-management/directory-descriptor/${id}/permissions`,
        body: input,
      },
      { apiName: this.apiName },
    );

  checkFolderName = (input: CreateDirectoryInput) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/file-management/directory-descriptor/check-folder-name`,
        body: input,
      },
      { apiName: this.apiName },
    );

  constructor(private restService: RestService) {}
}
