import { mapEnumToOptions } from '@abp/ng.core';

export enum enumNotificationTriggerType {
  StatusChange,
}

export const notificationTriggerDisplayNames: Record<enumNotificationTriggerType, string> = {
  [enumNotificationTriggerType.StatusChange]: 'Status Change',
};

export const EnumNotificationStatusOptions = mapEnumToOptions(enumNotificationTriggerType);

export namespace enumNotificationTriggerType {
  export function getOptions(): any {
    return Object.entries(notificationTriggerDisplayNames).map(([id, description]) => ({
      id,
      description,
    }));
  }
}
