import type {
  ExtensibleAuditedEntityDto,
  ExtensibleObject,
  PagedAndSortedResultRequestDto,
} from '@abp/ng.core';
import type { FileIconInfo } from '../files/models';
import { ExifDataResultsDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';

export interface CreateDirectoryInput extends ExtensibleObject {
  parentId?: string;
  name: string;
  missionId?: string;
  captureId?: string;
  deliverableId?: string;
  orderDetailId?: string;
}

export interface DirectoryContentDto extends ExtensibleAuditedEntityDto<string> {
  id: string;
  name?: string;
  isDirectory: boolean;
  size: number;
  iconInfo: FileIconInfo;
  concurrencyStamp?: string;
  imagePreviewUrl?: string;
  videoPreviewUrl?: string;
  fileContentPreviewUrl?: string;
  thumbnailUrl?: string;
  exifData?: string;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
  isRoot?: boolean;
  parentId?: string;
  validationResults?: ExifDataResultsDto[];
  processingFinished: boolean;
  processingThumbnailFailed: boolean;
  processingExifDataFailed: boolean;
}

export interface ExifData {
  Latitude?: number;
  longitude?: number;
  RelativeHeight?: number;
  GimbalAngle?: string;
}

export interface DirectoryContentRequestInput extends PagedAndSortedResultRequestDto {
  filter?: string;
  id?: string;
}

export interface DirectoryDescriptorDto extends ExtensibleAuditedEntityDto<string> {
  name?: string;
  parentId?: string;
  concurrencyStamp?: string;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
}

export interface RootFolderDetailsDto {
  folderId?: string;
  size: number;
  itemsCount: number;
}

export interface RootFoldersDetailsDto {
  foldersId?: Array<string>;
  size: number;
  itemsCount: number;
}

export interface DirectoryDescriptorInfoDto {
  id?: string;
  name?: string;
  parentId?: string;
  hasChildren: boolean;
  canRead: boolean;
  canWrite: boolean;
  canDelete: boolean;
  captureId?: string;
  missionId?: string;
  deliverableId?: string;
  isRoot?: boolean;
}

export interface MoveDirectoryInput {
  id?: string;
  newParentId?: string;
  concurrencyStamp?: string;
}

export interface RenameDirectoryInput {
  name: string;
  concurrencyStamp?: string;
}

export interface FilterMission {
  missionId: string;
  captureId: string;
  deliverableId: string;
  orderDetailId: string;
  rootFolder?: { id: string; name: string };
}

export class FolderPermissionResultModel {
  read: Array<string>;
  write: Array<string>;
  delete: Array<string>;
}

export interface FileSelected {
  isSelected: boolean;
  item: DirectoryContentDto;
}

export interface SelectedAllDto {
  isSelected: boolean;
  folderId: string;
  folderName?: string;
}
